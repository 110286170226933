import { Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import RecipesSearch from "../modules/recipes/components/RecipesSearch";

export default function RecipesPage() {
  const navigate = useNavigate();

  function handleRecipeClick(recipe) {
    navigate(`/recipe/${recipe.recipeUuid}`);
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <RecipesSearch handleRecipeClick={handleRecipeClick} />
    </Container>
  );
}

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../common/contexts/Auth";
import { Image, Placeholder } from "cloudinary-react";
import {
  Grid,
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Box,
  Container,
  CircularProgress,
} from "@mui/material";
import styles from "../Recipes.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import TimerIcon from "@mui/icons-material/Timer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RecipeIngredientDisplay from "./RecipeIngredientDisplay";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import * as groceryListApi from "../../grocery-list/groceryListApi";

function RecipeDisplay(props) {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const recipe = props.recipe;
  const editingRecipe = props.editingRecipe;
  const recipeInstructions = recipe.recipeInstructions || [];
  const recipeTags = recipe.tags || [];
  const recipeIngredients = recipe.recipeIngredients || [];

  const { mutate: addRecipeToGroceryListMutation, isLoading: isAddIngredientsLoading } = useMutation(
    async () => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.addRecipe(recipe.id, token);
    },
    {
      onSuccess: (res) => {
        toast.success(`${res} ingrédients ont été ajoutés à votre liste d'épicerie!`);
      },
      useErrorBoundary: (error) => error.response?.status !== 402,
      onError: (error) => {
        if (error.response?.status === 402) {
          toast.error(
            "Vous avez atteint le nombre limite d'articles permis dans votre panier. Contactez-nous, il nous fera plaisir d'augmenter votre limite."
          );
        }
      },
    }
  );

  function displayImage() {
    if (recipe.image) {
      if (recipe.image.imageData) {
        return <img src={recipe.image.imageData} alt={recipe.name} className={styles.recipeImage} />;
      } else if (recipe.image.imagePublicId) {
        return (
          <Image cloudName="mealzer" publicId={recipe.image.imagePublicId} dpr="auto" className={styles.recipeImage}>
            <Placeholder />
          </Image>
        );
      } else if (recipe.image.imageSource) {
        return <img src={recipe.image.imageSource} alt={recipe.name} className={styles.recipeImage} />;
      }
    }

    return null;
  }

  function displayRecipeTitleCard() {
    return recipe.name || recipe.prepTime || recipe.cookTime || recipe.yield || recipeTags.length > 0 || recipe.image;
  }

  return (
    <div className="RecipeDisplay">
      <Container>
        <Grid container spacing={5} direction="row" alignItems="flex-start">
          {displayRecipeTitleCard() && (
            <Grid item md={12} style={{ width: "100%" }}>
              <Card hidden={true} className={styles.recipeTitleCard}>
                <CardMedia component={() => displayImage()} />
                <CardContent>
                  <div className={styles.recipeName}>
                    <Typography align="left" variant="h4">
                      {recipe.name}
                    </Typography>
                  </div>
                  <Typography variant="h6" align="left">
                    {recipe.recipeSource && <a href={recipe.recipeSource}>{new URL(recipe.recipeSource).hostname}</a>}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {recipe.prepTime > 0 && (
                      <div>
                        <TimerIcon /> Préparation : {recipe.prepTime} minutes
                      </div>
                    )}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {recipe.cookTime > 0 && (
                      <div>
                        <TimerIcon /> Cuisson : {recipe.cookTime} minutes
                      </div>
                    )}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {recipe.cookTime * 1 + recipe.prepTime * 1 > 0 && (
                      <div>
                        <TimerIcon /> Total : {recipe.cookTime * 1 + recipe.prepTime * 1} minutes
                      </div>
                    )}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {recipe.yield > 0 && (
                      <div>
                        <RestaurantIcon /> Portions : {recipe.yield}
                      </div>
                    )}
                  </Typography>
                  <Typography variant="h6" align="left">
                    {recipeTags &&
                      recipeTags.map((recipeTag) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          key={recipeTag.name}
                          label={recipeTag.name}
                          className={styles.recipeTagChip}
                        />
                      ))}
                  </Typography>
                  {!editingRecipe && (
                    <div>
                      <Box m={2} align="left">
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<EditIcon />}
                          mt={1}
                          onClick={() => navigate(`/recipe/edit/${recipe.recipeUuid}`)}>
                          Modifier la recette
                        </Button>
                      </Box>
                      <Box m={2} align="left">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={addRecipeToGroceryListMutation}
                          disabled={isAddIngredientsLoading}
                          startIcon={isAddIngredientsLoading ? <CircularProgress size={20} /> : <AddShoppingCartIcon />}
                          mt={1}>
                          Ajouter à votre liste d'épicerie
                        </Button>
                      </Box>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {recipeIngredients && recipeIngredients.length > 0 && (
            <Grid item md={5}>
              <Typography variant="h6">Ingrédients</Typography>
              {recipeIngredients.map((recipeIngredient, index) => (
                <Typography key={index} variant="body1" sx={{ m: 0.75 }}>
                  <RecipeIngredientDisplay
                    recipeIngredient={recipeIngredient}
                    unitsOfMeasurement={props.unitsOfMeasurementOptions}
                  />
                </Typography>
              ))}
            </Grid>
          )}
          {recipeInstructions && recipeInstructions.length > 0 && (
            <Grid item md={7}>
              <Typography variant="h6">Étapes</Typography>
              {recipeInstructions.map((recipeStep, index) => (
                <Typography key={index} variant="body1" sx={{ m: 0.75 }}>
                  <b>{recipeStep.number}.</b>
                  {recipeStep.description}
                </Typography>
              ))}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default RecipeDisplay;

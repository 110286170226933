import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import PrivateRouteWrapper from "./common/components/PrivateRouteWrapper";
import ScrollToTop from "./common/components/ScrollToTop";
import { AuthProvider } from "./common/contexts/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import "./common/styles/Main.scss";

import Navbar from "./modules/navigation/components/Navbar";
import Footer from "./modules/navigation/components/Footer";

import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "react-query";

import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import LoginPage from "./pages/LoginPage";
import RecipesPage from "./pages/RecipesPage";
import RecipePage from "./pages/RecipePage";
import AddRecipePage from "./pages/AddRecipePage";
import EditRecipePage from "./pages/EditRecipePage";
import MealsPlannerPage from "./pages/MealsPlannerPage";
import GroceryListPage from "./pages/GroceryListPage";
import NotFoundPage from "./pages/NotFoundPage";
import Error from "./common/components/Error";
import PantryPage from "./pages/PantryPage";

const theme = createTheme();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 500,
    },
  },
});

function App() {
  const [errorBoudaryKey, setErrorBoudaryKey] = useState(0);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
              />
              <BrowserRouter>
                <ScrollToTop />
                <QueryErrorResetBoundary>
                  {({ reset }) => (
                    <>
                      <Navbar
                        resetErrorBoudary={() => {
                          reset();
                          setErrorBoudaryKey(errorBoudaryKey + 1);
                        }}
                      />
                      <Box className="mainContent">
                        <ErrorBoundary FallbackComponent={Error} onReset={reset} resetKeys={[errorBoudaryKey]}>
                          <Routes>
                            <Route path="/" exact element={<HomePage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route element={<PrivateRouteWrapper />}>
                              <Route exact path="/recipe/:id" element={<RecipePage />} />
                              <Route path="/recipes" element={<RecipesPage />} />
                              <Route path="/addrecipe" element={<AddRecipePage />} />
                              <Route path="/recipe/edit/:id" element={<EditRecipePage />} />
                              <Route exact path="/planner" element={<MealsPlannerPage />} />
                              <Route exact path="/grocerylist" element={<GroceryListPage />} />
                              <Route exact path="/pantry" element={<PantryPage />} />
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                          </Routes>
                        </ErrorBoundary>
                      </Box>
                      <Footer />
                    </>
                  )}
                </QueryErrorResetBoundary>
              </BrowserRouter>
            </AuthProvider>
          </QueryClientProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

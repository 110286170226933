import * as yup from "yup";

const urlValidationSchema = yup.object({
  url: yup
    .string()
    .required("L'adresse web est obligatoire")
    .matches(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      "Cette addresse n'est pas conforme"
    ),
});

export default urlValidationSchema;

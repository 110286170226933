import * as yup from "yup";

const pantryItemValidationSchema = yup.object({
  name: yup
    .string()
    .required("Le nom de l'ingrédient est obligatoire")
    .max(100, "Le nom de l'ingrédient ne peut contenir plus de 100 caractères"),
});

export default pantryItemValidationSchema;

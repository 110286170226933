import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import styles from "../navigation.module.scss";

function Footer() {
  return (
    <div className={styles.footer}>
      <AppBar position="relative" className={styles.footerAppBar}>
        <Toolbar>
          <Typography variant="body1" color="inherit">
            &copy; 2022 Mealzer
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Footer;

import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import RecipeIngredientUnitAutocomplete from "../../recipes/components/RecipeIngredientUnitAutocomplete";
import RecipeIngredientNameAutocomplete from "../../recipes/components/RecipeIngredientNameAutocomplete";
import { useFormik } from "formik";
import ingredientValidationSchema from "../../../common/utils/ingredientValidationSchema";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "react-query";
import * as groceryListApi from "../groceryListApi";
import { AuthContext } from "../../../common/contexts/Auth";
import { toast } from "react-toastify";

export default function GroceryListItemEditorDialog(props) {
  const { currentUser } = useContext(AuthContext);
  const [validateIngredientEditor, setValidateIngredientEditor] = useState(false);
  const queryClient = useQueryClient();

  const unitsOfMeasurementOptions = props.unitsOfMeasurementOptions;
  const groceryProductsOptions = props.groceryProductsOptions;

  const { mutate: addGroceryListItemMutation, isLoading: addItemLoading } = useMutation(
    async (newItem) => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.addItem(newItem, token);
    },
    {
      onSuccess: () => {
        toast.success("L'article a été ajouté à votre liste d'épicerie.");
        queryClient.invalidateQueries("groceryList");
        props.closeDialog();
        setValidateIngredientEditor(false);
        ingredientFormik.resetForm();
      },
      useErrorBoundary: (error) => error.response?.status !== 402,
      onError: (error) => {
        if (error.response?.status === 402) {
          toast.error(
            "Vous avez atteint le nombre limite d'articles permis dans votre panier. Contactez-nous, il nous fera plaisir d'augmenter votre limite."
          );
        }
      },
    }
  );

  const { mutate: updateGroceryListItemMutation, isLoading: updateItemLoading } = useMutation(
    async (updatedItem) => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.updateItem(updatedItem, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("groceryList");
        props.closeDialog();
        setValidateIngredientEditor(false);
        ingredientFormik.resetForm();
      },
      useErrorBoundary: true,
    }
  );

  const ingredientFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateIngredientEditor,
    validateOnBlur: false,
    initialValues: {
      amount: props.editingGroceryListItem?.amount || "",
      unitOfMeasurementId: props.editingGroceryListItem?.unitOfMeasurementId,
      name: props.editingGroceryListItem?.name || "",
      details: props.editingGroceryListItem?.details || "",
    },
    validationSchema: ingredientValidationSchema,
    onSubmit: handleSubmit,
  });

  function closeDialog() {
    props.closeDialog();
    ingredientFormik.resetForm();
  }

  function setIngredientName(name) {
    ingredientFormik.setFieldValue("name", name);
  }

  function setIngredientUnitOfMeasurementId(unitOfMeasurementId) {
    ingredientFormik.setFieldValue("unitOfMeasurementId", unitOfMeasurementId);
  }

  function saveIngredient(event) {
    event.preventDefault();
    setValidateIngredientEditor(true);
    ingredientFormik.submitForm();
  }

  function createCompleteGroceryListItem(groceryListItem) {
    var completeGroceryListItem = groceryListItem;
    if (groceryProductsOptions && completeGroceryListItem.name) {
      var selectedGroceryProduct = groceryProductsOptions.find(
        (g) => g.name.toUpperCase() === completeGroceryListItem.name.toUpperCase()
      );
      if (selectedGroceryProduct) {
        completeGroceryListItem.name = selectedGroceryProduct.name;
        completeGroceryListItem.groceryProductId = selectedGroceryProduct.id;
      }
    }
    return completeGroceryListItem;
  }

  function handleSubmit() {
    const completeGroceryListItem = createCompleteGroceryListItem(ingredientFormik.values);
    if (props.editingGroceryListItem) {
      completeGroceryListItem.id = props.editingGroceryListItem.id;
      completeGroceryListItem.groceryCategoryId = props.editingGroceryListItem.groceryCategoryId;
      updateGroceryListItemMutation(completeGroceryListItem);
    } else {
      addGroceryListItemMutation(completeGroceryListItem);
    }
  }

  return (
    <div>
      <Dialog open={props.openDialog} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {`${props.editingGroceryListItem ? "Modifier" : "Ajouter"} un article à votre liste d'épicerie`}
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  closeDialog();
                }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <form style={{ width: "100%" }} onSubmit={saveIngredient}>
          <DialogContent>
            <Grid container spacing={2} direction="row">
              <Grid item xs={2}>
                <TextField
                  type="number"
                  label="Quantité"
                  name="amount"
                  value={ingredientFormik.values.amount}
                  helperText={ingredientFormik.errors.amount}
                  error={!!ingredientFormik.errors.amount}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    ingredientFormik.handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <RecipeIngredientUnitAutocomplete
                  unitsOfMeasurementOptions={unitsOfMeasurementOptions}
                  selectedUnitOfMeasurementId={ingredientFormik.values.unitOfMeasurementId}
                  setIngredientUnitOfMeasurementId={setIngredientUnitOfMeasurementId}
                />
              </Grid>
              <Grid item xs={5}>
                <RecipeIngredientNameAutocomplete
                  groceryProductsOptions={groceryProductsOptions}
                  groceryProductName={ingredientFormik.values.name}
                  setIngredientName={setIngredientName}
                  error={ingredientFormik.errors.name}
                  variant="standard"
                  startAdornment={
                    <React.Fragment>
                      <SearchIcon />
                    </React.Fragment>
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  label="Détails"
                  name="details"
                  helperText={ingredientFormik.errors.details}
                  error={!!ingredientFormik.errors.details}
                  value={ingredientFormik.values.details}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    ingredientFormik.handleChange(e);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={addItemLoading || updateItemLoading}
              startIcon={addItemLoading || updateItemLoading ? <CircularProgress size={20} /> : ""}>
              {props.editingGroceryListItem ? "Sauvegarder" : "Ajouter"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../common/contexts/Auth";
import RecipeDisplay from "../modules/recipes/components/RecipeDisplay";
import { useQuery } from "react-query";
import * as recipesApi from "../modules/recipes/recipesApi";
import * as baseApi from "../common/services/baseApi";
import Loading from "../common/components/Loading";

export default function RecipePage() {
  const { currentUser } = useContext(AuthContext);
  const params = useParams();

  const { data: recipe, isLoading } = useQuery(
    ["recipe", params.id],
    async () => {
      const token = await currentUser.getIdToken();
      return await recipesApi.getRecipe(params.id, token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { data: unitsOfMeasurementOptions, isLoading: unitsIsLoading } = useQuery(
    "unitsOfMeasurement",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getUnitsOfMeasurement(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  if (isLoading || unitsIsLoading) {
    return <Loading />;
  }

  return (
    <div className="Recipe">
      <RecipeDisplay recipe={recipe} unitsOfMeasurementOptions={unitsOfMeasurementOptions} />
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import stepValidationSchema from "../../../common/utils/stepValidationSchema";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import FormikTextField from "../../../common/components/FormikTextField";

function RecipeInstructionsEditorDialog(props) {
  const [instructions, setInstructions] = useState([]);
  const [instructionEditingIndex, setInstructionEditingIndex] = useState(undefined);
  const [validateInstructionEditor, setValidateInstructionEditor] = useState(false);
  const [closingDialog, setClosingDialog] = useState(false);
  const instructionFormikRef = useRef();

  useEffect(() => {
    setInstructions(props.instructions || []);
  }, [props.instructions]);

  function handleOpenInstructionEditor(instructionIndex, event) {
    event.stopPropagation();
    if (instructionEditingIndex === undefined) {
      setInstructionEditingIndex(instructionIndex);
    } else {
      setValidateInstructionEditor(true);
      instructionFormikRef.current.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setInstructions((prevInstructions) => {
            let newRecipeInstructions = [...prevInstructions];
            newRecipeInstructions[instructionEditingIndex] = {
              description: instructionFormikRef.current.values.description,
            };
            return newRecipeInstructions;
          });
          setValidateInstructionEditor(false);
          setInstructionEditingIndex(instructionIndex);
        }
      });
    }
  }

  function handleAddNewInstructionEditor(event) {
    event.stopPropagation();
    if (instructionEditingIndex === undefined) {
      setInstructionEditingIndex(instructions.length);
      setInstructions([...instructions, { description: "" }]);
    } else {
      setValidateInstructionEditor(true);
      instructionFormikRef.current.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setValidateInstructionEditor(false);
          setInstructions((prevInstructions) => {
            let newRecipeInstructions = [...prevInstructions];
            newRecipeInstructions[instructionEditingIndex] = instructionFormikRef.current.values;
            newRecipeInstructions = [...newRecipeInstructions, { description: "" }];
            return newRecipeInstructions;
          });
          setInstructionEditingIndex(instructions.length);
        }
      });
    }
  }

  function handleCloseDialogWithSave(event) {
    event.stopPropagation();
    if (instructionEditingIndex === undefined) {
      props.saveInstructions(instructions);
      props.closeDialog();
    } else {
      setValidateInstructionEditor(true);
      instructionFormikRef.current.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setClosingDialog(true);
          setInstructions((prevInstructions) => {
            let newRecipeInstructions = [...prevInstructions];
            newRecipeInstructions[instructionEditingIndex] = instructionFormikRef.current.values;
            return newRecipeInstructions;
          });
        }
      });
    }
  }

  function handleCloseDialogWithoutSave(event) {
    event.stopPropagation();
    setInstructionEditingIndex(undefined);
    setInstructions(props.instructions || []);
    props.closeDialog();
  }

  //When closing dialog and saving instructions
  useEffect(() => {
    if (closingDialog) {
      setClosingDialog(false);
      setInstructionEditingIndex(undefined);
      props.saveInstructions(instructions);
      props.closeDialog();
    }
    // eslint-disable-next-line
  }, [instructions]);

  function deleteInstruction(instructionIndex, event) {
    event.stopPropagation();
    if (instructionIndex === instructionEditingIndex) {
      setInstructionEditingIndex(undefined);
    } else if (instructionEditingIndex > instructionIndex) {
      setInstructionEditingIndex(instructionEditingIndex - 1);
    }

    setInstructions((prevInstructions) => {
      let newRecipeInstructions = [...prevInstructions];
      newRecipeInstructions.splice(instructionIndex, 1);
      return newRecipeInstructions;
    });
  }

  return (
    <div>
      <Dialog
        classes={{ paper: "absoluteDialog" }}
        open={props.open}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.closeDialog();
          }
        }}>
        <DialogTitle>
          <Typography variant={"h5"} component={"span"}>
            <p style={{ textAlign: "left" }}>
              Étapes
              <span style={{ float: "right" }}>
                {instructions && instructions.length > 20 ? (
                  <Tooltip title={"Une recette ne peut contenir plus de 20 étapes"}>
                    <span>
                      <Button variant="outlined" color="primary" startIcon={<AddIcon />} disabled>
                        Ajouter une étape
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={handleAddNewInstructionEditor}
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}>
                    Ajouter une étape
                  </Button>
                )}
              </span>
            </p>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List dense={true}>
            {instructions &&
              instructions.map((instruction, index) => {
                return (
                  <div key={index}>
                    {instructionEditingIndex === index ? (
                      <ListItem>
                        <Formik
                          innerRef={instructionFormikRef}
                          enableReinitialize={true}
                          validateOnChange={validateInstructionEditor}
                          validateOnBlur={false}
                          initialValues={{
                            description: instruction.description || "",
                          }}
                          validationSchema={stepValidationSchema}>
                          <Form style={{ width: "100%" }}>
                            <div onClick={(event) => event.stopPropagation()}>
                              <Grid container spacing={2} direction="row">
                                <Grid item xs={11}>
                                  <FormikTextField label="Description" name="description" size="small" multiline />
                                </Grid>
                              </Grid>
                            </div>
                          </Form>
                        </Formik>
                        <ListItemIcon>
                          <IconButton onClick={(event) => handleOpenInstructionEditor(undefined, event)} size="large">
                            <CheckIcon color="primary" />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemIcon>
                          <IconButton onClick={(event) => deleteInstruction(index, event)}>
                            <DeleteIcon color="secondary" size="large" />
                          </IconButton>
                        </ListItemIcon>
                      </ListItem>
                    ) : (
                      <ListItem button onClick={(event) => handleOpenInstructionEditor(index, event)}>
                        <ListItemText>
                          {index + 1}. {instruction.description}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={(event) => deleteInstruction(index, event)}
                            color="secondary"
                            size="large">
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </div>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogWithSave} variant="outlined" color="primary">
            Sauvergarder
          </Button>
          <Button onClick={handleCloseDialogWithoutSave} variant="outlined" color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RecipeInstructionsEditorDialog;

import { request } from "../../common/services/baseApi";

export const getGroceryList = (token) => {
  return request({
    url: "/api/grocery-list",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addRecipe = (recipeId, token) => {
  return request({
    url: `/api/grocery-list/recipe/${recipeId}`,
    method: "post",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addMeals = (dateRange, token) => {
  return request({
    url: "/api/grocery-list/meals",
    method: "post",
    data: dateRange,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addItem = (newItem, token) => {
  return request({
    url: "/api/grocery-list/item",
    method: "post",
    data: newItem,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateItem = (updateItem, token) => {
  return request({
    url: "/api/grocery-list/item",
    method: "put",
    data: updateItem,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const toggleItemStrikeThrough = (itemId, token) => {
  return request({
    url: `/api/grocery-list/item/strikethrough/${itemId}`,
    method: "put",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteItem = (itemId, token) => {
  return request({
    url: `/api/grocery-list/item/${itemId}`,
    method: "delete",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteRecipe = (recipeId, token) => {
  return request({
    url: `/api/grocery-list/recipe/${recipeId}`,
    method: "delete",
    headers: { Authorization: `Bearer ${token}` },
  });
};

import * as yup from "yup";

const recipeValidationSchema = yup.object({
  name: yup
    .string()
    .required("Le nom est obligatoire")
    .max(50, "Le nom est trop long"),
  prepTime: yup
    .number()
    .typeError("Le temps de préparation est invalide")
    .required("Le temps de préparation est obligatoire")
    .positive("Le temps de préparation est invalide")
    .integer("Le temps de préparation est invalide"),
  cookTime: yup
    .number()
    .typeError("Le temps de cuisson est invalide")
    .required("Le temps de cuisson est obligatoire")
    .positive("Le temps de cuisson est invalide")
    .integer("Le temps de cuisson est invalide"),
  yield: yup
    .number()
    .typeError("Le nombre de portions est invalide")
    .required("Le nombre de portions est obligatoire")
    .positive("Le nombre de portions est invalide")
    .integer("Le nombre de portions est invalide"),
  tags: yup
    .array()
    .min(1, "La recette doit contenir au moins une catégorie")
    .max(5, "La recette ne peut contenir plus de 5 catégories"),
});

export default recipeValidationSchema;

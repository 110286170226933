import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cloudinary } from "cloudinary-core";
import { AuthContext } from "../common/contexts/Auth";
import {
  Typography,
  Grid,
  Button,
  Chip,
  Avatar,
  Container,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AddMealDialog from "../modules/meals/components/AddMealDialog";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styles from "../modules/meals/MealsPlanner.module.scss";
import AddMealsToGroceryListDialog from "../modules/meals/components/AddMealsToGroceryListDialog";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as mealsApi from "../modules/meals/mealsApi";

const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const weekDayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

const mealTypes = ["Breakfast", "Lunch", "Diner"];
const mealTypesFrench = ["Déjeuner", "Diner", "Souper"];

export default function MealsPlannerPage() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [selectedMonth, setSelectedMonth] = useState("Janvier");
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [weekDates, setWeekDates] = useState([]);
  const [selectedMealPeriod, setSelectedMealPeriod] = useState({
    date: "",
    mealType: "",
  });
  const [openAddMealDialog, setOpenAddMealDialog] = useState(false);
  const [openAddMealsToGroceryListDialog, setOpenAddMealsToGroceryListDialog] = useState(false);
  const [chipIsHovered, setChipIsHovered] = useState(false);

  const queryClient = useQueryClient();

  const { data: weekMeals } = useQuery(
    ["meals", selectedDate.toDateString()],
    async () => {
      const token = await currentUser.getIdToken();
      return await mealsApi.getMeals(token, selectedDate.toDateString());
    },
    {
      useErrorBoundary: true,
    }
  );

  const { mutate: addMealMutation } = useMutation(
    async (newMeal) => {
      const token = await currentUser.getIdToken();
      return await mealsApi.addMeal(newMeal, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("meals");
        queryClient.invalidateQueries("numberMealsSelected");
      },
      useErrorBoundary: (error) => error.response?.status !== 402,
      onError: (error) => {
        if (error.response?.status === 402) {
          toast.error(
            "Vous avez atteint le nombre limite de repas permis. Contactez-nous, il nous fera plaisir d'augmenter votre limite."
          );
        }
      },
    }
  );

  const { mutate: deleteMealMutation } = useMutation(
    async (mealId) => {
      setChipIsHovered(false);
      const token = await currentUser.getIdToken();
      return await mealsApi.deleteMeal(mealId, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("meals");
        queryClient.invalidateQueries("numberMealsSelected");
      },
      useErrorBoundary: true,
    }
  );

  useEffect(() => {
    getWeekDates();
    // eslint-disable-next-line
  }, [selectedDate]);

  useEffect(() => {
    if (weekDates.length > 0) {
      if (weekDates[0].date.getMonth() !== weekDates[6].date.getMonth()) {
        setSelectedMonth(
          monthNames[weekDates[0].date.getMonth()] +
            " - " +
            monthNames[weekDates[6].date.getMonth()] +
            " " +
            weekDates[0].date.getFullYear()
        );
      } else {
        setSelectedMonth(monthNames[weekDates[0].date.getMonth()] + " " + weekDates[0].date.getFullYear());
      }
    }
    // eslint-disable-next-line
  }, [weekDates]);

  function getWeekDates() {
    var selectedDateTemp = new Date(selectedDate);
    var arrayWeek = [];
    for (var i = 1; i < 8; i++) {
      var day = selectedDateTemp.getDay();
      if (day === 0) {
        day = 7;
      }
      var diff = selectedDateTemp.getDate() - day + i;
      var date = new Date(selectedDateTemp.setDate(diff));
      arrayWeek.push({ date: date });
    }
    setWeekDates(arrayWeek);
  }

  function addMeal(mealObject) {
    addMealMutation(mealObject);
    setOpenAddMealDialog(false);
  }

  function handleRecipeClick(recipe) {
    var mealObject = {
      Date: selectedMealPeriod.date.toDateString(),
      MealType: selectedMealPeriod.mealType,
      RecipeId: recipe.id,
    };

    addMeal(mealObject);
  }

  function handleAddPersonalizedMeal(formData) {
    var mealObject = {
      Date: selectedMealPeriod.date.toDateString(),
      MealType: selectedMealPeriod.mealType,
      Description: formData.description,
      RecipeId: null,
    };

    addMeal(mealObject);
  }

  function incrementWeek() {
    var diff = selectedDate.getDate() + 7;
    setSelectedDate(new Date(selectedDate.setDate(diff)));
  }

  function decrementWeek() {
    var diff = selectedDate.getDate() - 7;
    setSelectedDate(new Date(selectedDate.setDate(diff)));
  }

  function mealIsSameDayAndMealType(meal, day, mealType) {
    var mealDate = new Date(meal.date);

    return (
      mealDate.getFullYear() === day.date.getFullYear() &&
      mealDate.getMonth() === day.date.getMonth() &&
      mealDate.getDate() === day.date.getDate() &&
      meal.mealType === mealType
    );
  }

  function handlePlannerZoneClick(date, mealType) {
    setOpenAddMealDialog(true);
    setSelectedMealPeriod({ date: date, mealType: mealType });
  }

  const displayImage = (recipe) => {
    if (recipe.image && recipe.image.imageSource) {
      return <Avatar alt={recipe.name} src={recipe.image.imageSource} />;
    } else if (recipe.image && recipe.image.imagePublicId) {
      const cloudinaryCore = new Cloudinary({ cloud_name: "mealzer" });
      return <Avatar alt={recipe.name} src={cloudinaryCore.url(recipe.image.imagePublicId)} />;
    } else {
      return null;
    }
  };

  const displayMeal = (meal) => {
    if (meal.recipe) {
      return (
        <div
          className={styles.mealChipMargins}
          onMouseOver={() => setChipIsHovered(true)}
          onMouseLeave={() => setChipIsHovered(false)}>
          <Chip
            key={meal.id}
            className={styles.mealChip}
            clickable
            variant="outlined"
            avatar={displayImage(meal.recipe)}
            onDelete={() => deleteMealMutation(meal.id)}
            onClick={() => navigate(`/recipe/${meal.recipe.recipeUuid}`)}
            label={<Typography className={styles.mealChipText}>{meal.recipe.name}</Typography>}
          />
        </div>
      );
    } else {
      return (
        <div
          className={styles.mealChipMargins}
          onMouseOver={() => setChipIsHovered(true)}
          onMouseLeave={() => setChipIsHovered(false)}>
          <Chip
            key={meal.id}
            className={styles.mealChip}
            variant="outlined"
            onDelete={() => deleteMealMutation(meal.id)}
            label={<Typography className={styles.mealChipText}>{meal.description}</Typography>}
          />
        </div>
      );
    }
  };

  return (
    <div className="MealsPlanner">
      <Container maxWidth="xl" disableGutters>
        <Grid container>
          <Grid item container sm={6} justifyContent={"flex-start"}>
            <Typography variant="h4">Planificateur de repas</Typography>
          </Grid>
          <Grid item container sm={6} justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenAddMealsToGroceryListDialog(true)}
              startIcon={<AddShoppingCartIcon />}
              mt={1}>
              Ajouter des repas à votre liste d'épicerie
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item>
            <IconButton onClick={decrementWeek} size="large">
              <NavigateBeforeIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={incrementWeek} size="large">
              <NavigateNextIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5">{selectedMonth}</Typography>
          </Grid>
        </Grid>

        <TableContainer component={Paper} style={{ maxWidth: "100%" }}>
          <Table style={{ minWidth: "1500px" }}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableCell} width={"5%"}></TableCell>
                {weekDates.map((day) => (
                  <TableCell key={day.date} className={styles.tableCell} width={"10%"}>
                    <Typography variant="h6" align="center">
                      {weekDayNames[day.date.getDay()]} {day.date.getDate()}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mealTypes.map((mealType, index) => (
                <TableRow key={mealType}>
                  <TableCell style={{ height: "15vh" }} className={styles.tableCell}>
                    <Typography variant="h6">{mealTypesFrench[index]}</Typography>
                  </TableCell>
                  {weekDates.map((day) => (
                    <TableCell
                      key={day.date}
                      onClick={() => {
                        handlePlannerZoneClick(day.date, mealType);
                      }}
                      className={`${styles.tableCell} ${chipIsHovered ? "" : styles.tableCellInteractive}`}>
                      {weekMeals &&
                        weekMeals.map((meal) => {
                          if (mealIsSameDayAndMealType(meal, day, mealType)) {
                            return displayMeal(meal);
                          }
                          return null;
                        })}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <AddMealDialog
        openDialog={openAddMealDialog}
        setOpenAddMealDialog={setOpenAddMealDialog}
        handleRecipeClick={handleRecipeClick}
        handleAddPersonalizedMeal={handleAddPersonalizedMeal}
      />

      <AddMealsToGroceryListDialog
        weekFirstDay={weekDates[0]}
        openDialog={openAddMealsToGroceryListDialog}
        setOpenAddMealsToGroceryListDialog={setOpenAddMealsToGroceryListDialog}></AddMealsToGroceryListDialog>
    </div>
  );
}

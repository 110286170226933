import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../common/contexts/Auth";
import RecipeEditor from "../modules/recipes/components/RecipeEditor";
import { useQuery } from "react-query";
import * as recipesApi from "../modules/recipes/recipesApi";
import * as baseApi from "../common/services/baseApi";
import Loading from "../common/components/Loading";

export default function EditRecipePage() {
  const { currentUser } = useContext(AuthContext);
  const params = useParams();

  const { data: recipe, isLoading: recipeIsLoading } = useQuery(
    ["recipe", params.id],
    async () => {
      const token = await currentUser.getIdToken();
      return await recipesApi.getRecipe(params.id, token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { data: recipeTagsChoices, isLoading: tagsIsLoading } = useQuery(
    "tags",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getTags(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { data: unitsOfMeasurementOptions, isLoading: unitsIsLoading } = useQuery(
    "unitsOfMeasurement",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getUnitsOfMeasurement(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  if (recipeIsLoading || tagsIsLoading || unitsIsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <RecipeEditor
        recipe={recipe}
        recipeTagsChoices={recipeTagsChoices}
        unitsOfMeasurementOptions={unitsOfMeasurementOptions}
      />
    </div>
  );
}

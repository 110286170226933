import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Container } from "@mui/material";
import { AuthContext } from "../contexts/Auth";
import MenuBookIcon from "@mui/icons-material/MenuBook";

export default function Error(props) {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  console.log(props.error);
  return (
    <Container>
      <Typography variant="h3">Oups !</Typography>
      <Typography variant="h6">
        Une erreur s'est produite de notre côté. Contactez nous si le problème persiste.
      </Typography>
      <Button
        className="buttonMargins"
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => {
          props.resetErrorBoundary();
          navigate("/recipes", { replace: true });
        }}
        startIcon={<MenuBookIcon />}>
        {currentUser ? "Livre de recettes" : "Page d'acceuil"}
      </Button>
      <Button
        className="buttonMargins"
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => window.location.reload()}>
        {"Recharger cette page"}
      </Button>
    </Container>
  );
}

import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import * as groceryListApi from "../groceryListApi";
import { AuthContext } from "../../../common/contexts/Auth";
import CloseIcon from "@mui/icons-material/Close";

export default function GroceryListItemCategoryEditorDialog(props) {
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [groceryCategoryId, setGroceryCategoryId] = useState(props.editingGroceryListItem?.groceryCategoryId || 0);

  const { mutate: updateGroceryListItemMutation, isLoading: updateItemLoading } = useMutation(
    async (updatedItem) => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.updateItem(updatedItem, token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("groceryList");
        closeDialog();
      },
      useErrorBoundary: true,
    }
  );

  useEffect(() => {
    setGroceryCategoryId(props.editingGroceryListItem?.groceryCategoryId || 0);
  }, [props.editingGroceryListItem?.groceryCategoryId]);

  function closeDialog() {
    props.closeDialog();
  }

  function handleChange(event) {
    setGroceryCategoryId(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (props.editingGroceryListItem) {
      const newGroceryListItem = props.editingGroceryListItem;
      newGroceryListItem.groceryCategoryId = groceryCategoryId;
      updateGroceryListItemMutation(newGroceryListItem);
    }
  }

  return (
    <div>
      {props.editingGroceryListItem && (
        <Dialog open={props.openDialog} onClose={closeDialog} fullWidth maxWidth="sm">
          <DialogTitle>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                {`Choisissez une catégorie pour ${
                  props.editingGroceryListItem.name || props.editingGroceryListItem.details
                }`}
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    closeDialog();
                  }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                <Select value={groceryCategoryId} onChange={handleChange} autoWidth>
                  {props.groceryCategoriesOptions.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={updateItemLoading}
                startIcon={updateItemLoading ? <CircularProgress size={20} /> : ""}>
                Sauvegarder
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

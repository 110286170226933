import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";

function FormikTextField(props) {
  const [field, meta] = useField(props);
  const errorText = meta.error ? meta.error : "";
  return (
    <TextField
      {...field}
      label={props.label}
      helperText={errorText}
      error={!!errorText}
      InputProps={props.InputProps}
      multiline={props.multiline}
      fullWidth
      variant="standard"
    />
  );
}

export default FormikTextField;

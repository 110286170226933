import firebase from "firebase/compat/app";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAFLkxLtgedPVwtQC3xYZXTQkpsuiiE4gw",
  authDomain: "mealzer-7d76e.firebaseapp.com",
  databaseURL: "https://mealzer-7d76e.firebaseio.com",
  projectId: "mealzer-7d76e",
  storageBucket: "mealzer-7d76e.appspot.com",
  messagingSenderId: "400936865158",
  appId: "1:400936865158:web:0ec07fb0d0a644c6003007",
  measurementId: "G-QEBCLTWLXL",
});

export default app;

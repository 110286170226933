import { request } from "../../common/services/baseApi";

export const searchRecipes = (token, recipeName, selectedTags, recipesPerPage, page) => {
  const searchRecipesObject = {
    name: recipeName,
    tags: selectedTags,
    recipesPerPage: recipesPerPage,
    page: page,
  };
  return request({
    url: "/api/recipes/search",
    method: "post",
    data: searchRecipesObject,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getRecipe = (id, token) => {
  return request({ url: `/api/recipes/${id}`, headers: { Authorization: `Bearer ${token}` } });
};

export const addRecipe = (newRecipe, token) => {
  return request({
    url: "/api/recipes",
    method: "post",
    data: newRecipe,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const udpdateRecipe = (updatedRecipe, token) => {
  return request({
    url: "/api/recipes",
    method: "put",
    data: updatedRecipe,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteRecipe = (id, token) => {
  return request({
    url: `/api/recipes/${id}`,
    method: "delete",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const scrapeRecipeFromImage = (imagesObject, token) => {
  return request({
    url: "/api/recipe-scraper/image",
    method: "post",
    data: imagesObject,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const scrapeRecipeFromWeb = (url, token) => {
  return request({
    url: `/api/recipe-scraper/web?url=${url}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

import { request } from "../../common/services/baseApi";

export const getMeals = (token, date) => {
  return request({
    url: `/api/meals/week?date=${date}`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addMeal = (newMeal, token) => {
  return request({
    url: "/api/meals",
    method: "post",
    data: newMeal,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteMeal = (id, token) => {
  return request({
    url: `/api/meals/${id}`,
    method: "delete",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getNumberMealsSelected = (dateRange, token) => {
  return request({
    url: `/api/meals/count-between-dates`,
    method: "post",
    data: dateRange,
    headers: { Authorization: `Bearer ${token}` },
  });
};

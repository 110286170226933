import * as yup from "yup";

const ingredientValidationSchema = yup.object().shape(
  {
    amount: yup
      .number()
      .typeError("La quantité est invalide")
      .positive("La quantité est invalide")
      .when("unitOfMeasurementId", (unitOfMeasurement) => {
        if (unitOfMeasurement) return yup.string().required("Quantité obligatoire si les unités sont spécifiées");
      }),
    name: yup
      .string()
      .required("Le nom de l'ingrédient est obligatoire")
      .max(100, "Le nom de l'ingrédient ne peut contenir plus de 100 caractères"),
    details: yup.string().max(100, "Les détails de l'ingrédient ne peut contenir plus de 100 caractères"),
  },
  [["name", "details"]]
);

export default ingredientValidationSchema;

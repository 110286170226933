import { request } from "../../common/services/baseApi";

export const getPantry = (token) => {
  return request({
    url: "/api/pantry",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addItem = (newItem, token) => {
  return request({
    url: "/api/pantry/item",
    method: "post",
    data: newItem,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteItem = (itemId, token) => {
  return request({
    url: `/api/pantry/item/${itemId}`,
    method: "delete",
    headers: { Authorization: `Bearer ${token}` },
  });
};

import React, { useContext, useState } from "react";
import { AuthContext } from "../../../common/contexts/Auth";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import urlValidationSchema from "../../../common/utils/urlValidationSchema";
import FormikTextField from "../../../common/components/FormikTextField";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

export default function RecipeImportFromWebDialog(props) {
  const { currentUser } = useContext(AuthContext);
  const [diaglogFormSubmitted, setDialogFormSubmitted] = useState(false);

  //When importing recipe from other website
  function handleImportRecipe(data, { setErrors, setSubmitting }) {
    setSubmitting(true);
    currentUser.getIdToken().then(function (idToken) {
      axios
        .get(`${process.env.REACT_APP_Mealzer_SERVER_URL}/api/recipe-scraper/web?url=${data.url}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          props.handleImportRecipe(res.data);
          props.close();
        })
        .catch(() => {
          setErrors({
            url: "Malheureusement, nous ne sommes pas en mesure d'extraire une recette de cette page.",
          });
          setSubmitting(false);
        });
    });
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{"Importer une recette à partir d'une page web"}</Box>
          <Box>
            <IconButton onClick={props.close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={diaglogFormSubmitted}
          validateOnBlur={false}
          initialValues={{
            url: "",
          }}
          validationSchema={urlValidationSchema}
          onSubmit={(data, { setErrors, setSubmitting }) => {
            handleImportRecipe(data, { setErrors, setSubmitting });
          }}>
          {({ isSubmitting }) => (
            <Form>
              <FormikTextField label="Adresse web de la recette" name={`url`} />
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setDialogFormSubmitted(true);
                  }}
                  disabled={isSubmitting}
                  startIcon={isSubmitting ? <CircularProgress size={20} /> : <DownloadIcon />}>
                  Importer
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_Mealzer_SERVER_URL,
});

export const request = ({ ...options }) => {
  const onSuccess = (response) => response.data;
  const onError = (error) => {
    throw error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export const getTags = (token) => {
  return request({ url: "/api/tags", headers: { Authorization: `Bearer ${token}` } });
};

export const getUnitsOfMeasurement = (token) => {
  return request({ url: "/api/units-of-measurement", headers: { Authorization: `Bearer ${token}` } });
};

export const getGroceryProducts = (token) => {
  return request({ url: "/api/grocery-products", headers: { Authorization: `Bearer ${token}` } });
};

export const getGroceryCategories = (token) => {
  return request({ url: "/api/grocery-products/categories", headers: { Authorization: `Bearer ${token}` } });
};

import React, { useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import app from "../common/utils/firebase";
import { AuthContext } from "../common/contexts/Auth";
import { Navigate } from "react-router-dom";
import { Typography, Card } from "@mui/material";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export default function LoginPage() {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="centerText">
      <Typography style={{ width: "100%" }} variant="h3">
        Se connecter
      </Typography>
      <br />
      <div className="centerDiv">
        <Card>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={app.auth()} />
        </Card>
      </div>
    </div>
  );
}

import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import app from "../../../common/utils/firebase";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  ListItemIcon,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AuthContext } from "../../../common/contexts/Auth";
import TodayIcon from "@mui/icons-material/Today";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import styles from "../navigation.module.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KitchenIcon from "@mui/icons-material/Kitchen";

function Navbar(props) {
  const { currentUser } = useContext(AuthContext);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const navigate = useNavigate();

  return (
    <div className={styles.navBar}>
      <AppBar color="inherit" position="fixed">
        <Toolbar component="nav">
          <Box display="flex" flexGrow={1}>
            <Link
              to={"/"}
              onClick={() => {
                props.resetErrorBoudary();
              }}>
              <img src={require("../../../assets/logo.png")} className="d-inline-block align-top" alt="" />
            </Link>
          </Box>
          {currentUser && (
            <Box>
              <Tooltip title="Livre de recettes">
                <IconButton
                  color="primary"
                  onClick={() => {
                    props.resetErrorBoudary();
                    navigate("/recipes");
                  }}
                  size="large">
                  <MenuBookIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Planificateur de repas">
                <IconButton
                  color="primary"
                  onClick={() => {
                    props.resetErrorBoudary();
                    navigate("/planner");
                  }}
                  size="large">
                  <TodayIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Liste d'épicerie">
                <IconButton
                  color="primary"
                  onClick={() => {
                    props.resetErrorBoudary();
                    navigate("/grocerylist");
                  }}
                  size="large">
                  <ShoppingCartIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)} color="primary">
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Box>
          )}
          {currentUser ? (
            <div></div>
          ) : (
            <Button variant="outlined" color="primary" onClick={() => navigate("/login")} startIcon={<PersonIcon />}>
              Se connecter
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />

      <Menu anchorEl={menuAnchor} keepMounted open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            navigate("/pantry");
          }}>
          <ListItemIcon>
            <KitchenIcon fontSize="medium" color="primary" />
          </ListItemIcon>
          <ListItemText>Mon Garde-Manger</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            app.auth().signOut();
          }}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="medium" color="secondary" />
          </ListItemIcon>
          <ListItemText>Déconnexion</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Navbar;

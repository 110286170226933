import React, { useContext } from "react";
import { AuthContext } from "../common/contexts/Auth";
import RecipeEditor from "../modules/recipes/components/RecipeEditor";
import { useQuery } from "react-query";
import * as baseApi from "../common/services/baseApi";
import Loading from "../common/components/Loading";

export default function AddRecipePage() {
  const { currentUser } = useContext(AuthContext);

  const { data: recipeTagsChoices, isLoading: tagsIsLoading } = useQuery(
    "tags",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getTags(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { data: unitsOfMeasurementOptions, isLoading: unitsIsLoading } = useQuery(
    "unitsOfMeasurement",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getUnitsOfMeasurement(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  if (tagsIsLoading || unitsIsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <RecipeEditor recipeTagsChoices={recipeTagsChoices} unitsOfMeasurementOptions={unitsOfMeasurementOptions} />
    </div>
  );
}

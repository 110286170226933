import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { AuthContext } from "../common/contexts/Auth";
import ListIcon from "@mui/icons-material/List";

export default function NotFoundPage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="centerText">
      <Typography variant="h3">Oups !</Typography>
      <Typography variant="h6">La page que vous recherchez semble introuvable</Typography>
      {currentUser ? (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => navigate("/recipes", { replace: true })}
          startIcon={<ListIcon />}>
          Livre de recettes
        </Button>
      ) : (
        <Button variant="outlined" color="primary" size="large" onClick={() => navigate("/", { replace: true })}>
          Page d'accueil
        </Button>
      )}
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  TextField,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import ingredientValidationSchema from "../../../common/utils/ingredientValidationSchema";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../../common/contexts/Auth";
import RecipeIngredientDisplay from "./RecipeIngredientDisplay";
import RecipeIngredientNameAutocomplete from "./RecipeIngredientNameAutocomplete";
import RecipeIngredientUnitAutocomplete from "./RecipeIngredientUnitAutocomplete";
import { useQuery } from "react-query";
import * as baseApi from "../../../common/services/baseApi";

export default function RecipeIngredientsEditorDialog(props) {
  const { currentUser } = useContext(AuthContext);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientEditingIndex, setIngredientEditingIndex] = useState(undefined);
  const [validateIngredientEditor, setValidateIngredientEditor] = useState(false);

  const { data: groceryProductsOptions } = useQuery("groceryProducts", async () => {
    const token = await currentUser.getIdToken();
    return await baseApi.getGroceryProducts(token);
  });

  useEffect(() => {
    setIngredients(props.ingredients || []);
  }, [props.ingredients]);

  const ingredientFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateIngredientEditor,
    validateOnBlur: false,
    initialValues: {
      amount: ingredients[ingredientEditingIndex]?.amount || "",
      unitOfMeasurementId: ingredients[ingredientEditingIndex]?.unitOfMeasurementId,
      name: ingredients[ingredientEditingIndex]?.name || "",
      details: ingredients[ingredientEditingIndex]?.details || "",
    },
    validationSchema: ingredientValidationSchema,
  });

  function completeIngredientEditing() {
    var completeIngredient = ingredientFormik.values;
    if (groceryProductsOptions && completeIngredient.name) {
      var selectedGroceryProduct = groceryProductsOptions.find(
        (g) => g.name.toUpperCase() === completeIngredient.name.toUpperCase()
      );
      if (selectedGroceryProduct) {
        completeIngredient.name = selectedGroceryProduct.name;
        completeIngredient.groceryProductId = selectedGroceryProduct.id;
      }
    }
    return completeIngredient;
  }

  function handleOpenIngredientEditor(ingredientIndex, event) {
    event.stopPropagation();
    if (ingredientEditingIndex === undefined && ingredientIndex !== undefined) {
      setValidateIngredientEditor(false);
      setIngredientEditingIndex(ingredientIndex);
    } else {
      setValidateIngredientEditor(true);
      ingredientFormik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setIngredients((prevIngredients) => {
            let newRecipeIngredients = [...prevIngredients];
            newRecipeIngredients[ingredientEditingIndex] = completeIngredientEditing();
            return newRecipeIngredients;
          });
          setIngredientEditingIndex(ingredientIndex);
        }
      });
    }
  }

  function handleAddNewIngredientEditor(event) {
    event.stopPropagation();
    if (ingredientEditingIndex === undefined) {
      setIngredients([
        ...ingredients,
        { amount: "", unitOfMeasurementId: null, name: "", details: "", groceryProductId: null },
      ]);
      setIngredientEditingIndex(ingredients.length);
    } else {
      setValidateIngredientEditor(true);
      ingredientFormik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setValidateIngredientEditor(false);
          setIngredients((prevIngredients) => {
            let newRecipeIngredients = [...prevIngredients];
            newRecipeIngredients[ingredientEditingIndex] = completeIngredientEditing();
            newRecipeIngredients = [
              ...newRecipeIngredients,
              { amount: "", unitOfMeasurementId: null, name: "", details: "", groceryProductId: null },
            ];
            return newRecipeIngredients;
          });
          setIngredientEditingIndex(ingredients.length);
        }
      });
    }
  }

  function handleCloseDialogWithSave(event) {
    event.stopPropagation();
    if (ingredientEditingIndex === undefined) {
      props.saveIngredients(ingredients);
      props.closeDialog();
    } else {
      setValidateIngredientEditor(true);
      ingredientFormik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          let newRecipeIngredients = ingredients;
          newRecipeIngredients[ingredientEditingIndex] = completeIngredientEditing();
          props.saveIngredients(newRecipeIngredients);
          setIngredients(ingredients);
          props.closeDialog();
          setIngredientEditingIndex(undefined);
        }
      });
    }
  }

  function handleCloseDialogWithoutSave(event) {
    event.stopPropagation();
    setIngredientEditingIndex(undefined);
    setIngredients(props.ingredients || []);
    props.closeDialog();
  }

  function handleDeleteIngredient(ingredientIndex, event) {
    event.stopPropagation();

    setIngredients((prevIngredients) => {
      let newRecipeIngredients = [...prevIngredients];
      newRecipeIngredients.splice(ingredientIndex, 1);
      return newRecipeIngredients;
    });

    if (ingredientIndex === ingredientEditingIndex) {
      setIngredientEditingIndex(undefined);
    } else if (ingredientEditingIndex > ingredientIndex) {
      setIngredientEditingIndex(ingredientEditingIndex - 1);
    }
  }

  function setIngredientName(name) {
    ingredientFormik.setFieldValue("name", name);
  }

  function setIngredientUnitOfMeasurementId(unitOfMeasurementId) {
    ingredientFormik.setFieldValue("unitOfMeasurementId", unitOfMeasurementId);
  }

  return (
    <div>
      <Dialog
        classes={{ paper: "absoluteDialog" }}
        open={props.open}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.closeDialog();
          }
        }}>
        <DialogTitle>
          <Typography variant={"h5"} component={"span"}>
            <p style={{ textAlign: "left" }}>
              Ingrédients
              <span style={{ float: "right" }}>
                {ingredients && ingredients.length > 100 ? (
                  <Tooltip title={"Une recette ne peut contenir plus de 100 ingrédients"}>
                    <span>
                      <Button variant="outlined" color="primary" startIcon={<AddIcon />} disabled>
                        Ajouter un ingrédient
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={handleAddNewIngredientEditor}
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}>
                    Ajouter un ingrédient
                  </Button>
                )}
              </span>
            </p>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List dense={true}>
            {ingredients &&
              ingredients.map((ingredient, index) => {
                return (
                  <div key={index}>
                    {ingredientEditingIndex === index ? (
                      <ListItem style={{ marginTop: "10px", marginBottom: "20px" }}>
                        <form style={{ width: "100%" }}>
                          <div onClick={(event) => event.stopPropagation()}>
                            <Grid container spacing={2} direction="row">
                              <Grid item xs={2}>
                                <TextField
                                  type="number"
                                  label="Quantité"
                                  name="amount"
                                  helperText={ingredientFormik.errors.amount}
                                  error={!!ingredientFormik.errors.amount}
                                  value={ingredientFormik.values.amount}
                                  fullWidth
                                  variant="standard"
                                  onChange={(e) => {
                                    ingredientFormik.handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <RecipeIngredientUnitAutocomplete
                                  unitsOfMeasurementOptions={props.unitsOfMeasurementOptions}
                                  selectedUnitOfMeasurementId={ingredientFormik.values.unitOfMeasurementId}
                                  setIngredientUnitOfMeasurementId={setIngredientUnitOfMeasurementId}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <RecipeIngredientNameAutocomplete
                                  groceryProductsOptions={groceryProductsOptions}
                                  groceryProductName={ingredientFormik.values.name}
                                  setIngredientName={setIngredientName}
                                  error={ingredientFormik.errors.name}
                                  variant="standard"
                                  startAdornment={
                                    <React.Fragment>
                                      <SearchIcon />
                                    </React.Fragment>
                                  }
                                />
                              </Grid>
                              <Grid item xs={11}>
                                <TextField
                                  label="Détails"
                                  name="details"
                                  helperText={ingredientFormik.errors.details}
                                  error={!!ingredientFormik.errors.details}
                                  value={ingredientFormik.values.details}
                                  fullWidth
                                  variant="standard"
                                  onChange={(e) => {
                                    ingredientFormik.handleChange(e);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </form>
                        <ListItemIcon>
                          <IconButton onClick={(event) => handleOpenIngredientEditor(undefined, event)} size="large">
                            <CheckIcon color="primary" />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemIcon>
                          <IconButton onClick={(event) => handleDeleteIngredient(index, event)}>
                            <DeleteIcon color="secondary" size="large" />
                          </IconButton>
                        </ListItemIcon>
                      </ListItem>
                    ) : (
                      <ListItem button onClick={(event) => handleOpenIngredientEditor(index, event)}>
                        <ListItemText>
                          <RecipeIngredientDisplay
                            recipeIngredient={ingredient}
                            unitsOfMeasurement={props.unitsOfMeasurementOptions}
                          />
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton onClick={(event) => handleDeleteIngredient(index, event)}>
                            <DeleteIcon color="secondary" size="large" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </div>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogWithSave} variant="outlined" color="primary">
            Sauvergarder
          </Button>
          <Button onClick={handleCloseDialogWithoutSave} variant="outlined" color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";

export default function RecipeIngredientNameAutocomplete(props) {
  const autoCompleteFilterOptions = createFilterOptions({
    limit: 50,
  });

  function handleAutoCompleteInputChange(event, value) {
    if (event !== null) {
      if (value !== null) {
        props.setIngredientName(value);
      }
    }
  }

  function handleAutoCompleteOnChange(event, value) {
    if (props.handleIngredientSelect) props.handleIngredientSelect(value?.name);
    else {
      value !== null ? props.setIngredientName(value.name) : props.setIngredientName(undefined);
    }
  }

  return (
    <Autocomplete
      freeSolo={true}
      options={props.groceryProductsOptions || []}
      getOptionLabel={(option) => option.name}
      value={{ name: props.groceryProductName || "" }}
      filterOptions={autoCompleteFilterOptions}
      fullWidth={true}
      onChange={handleAutoCompleteOnChange}
      onInputChange={handleAutoCompleteInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={props.variant}
          label="Ingrédient"
          helperText={props.error}
          error={!!props.error}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: props.startAdornment,
          }}
        />
      )}
    />
  );
}

import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../common/contexts/Auth";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import { toast } from "react-toastify";
import * as groceryListApi from "../../grocery-list/groceryListApi";
import * as mealsApi from "../mealsApi";
import { useQuery, useMutation } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

export default function AddMealsToGroceryListDialog(props) {
  const { currentUser } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState([null, null]);

  const { data: numberOfMealsSelected, isLoading } = useQuery(
    ["numberMealsSelected", dateRange[0]?.toDateString(), dateRange[1]?.toDateString()],
    async () => {
      const token = await currentUser.getIdToken();
      var dateRangeObject = {
        startDate: dateRange[0].toDateString(),
        endDate: dateRange[1].toDateString(),
      };
      return await mealsApi.getNumberMealsSelected(dateRangeObject, token);
    },
    { enabled: dateRange[0] !== null && dateRange[1] !== null }
  );

  const { mutate: addMealsToGroceryListMutation, isLoading: addingMealsLoading } = useMutation(
    async () => {
      const token = await currentUser.getIdToken();
      var dateRangeObject = {
        startDate: dateRange[0].toDateString(),
        endDate: dateRange[1].toDateString(),
      };
      return await groceryListApi.addMeals(dateRangeObject, token);
    },
    {
      onSuccess: (data) => {
        toast.success(`${data} articles ont été ajoutés à votre liste d'épicerie!`);
        closeDialog();
      },
      useErrorBoundary: (error) => error.response?.status !== 402,
      onError: (error) => {
        if (error.response?.status === 402) {
          toast.error(
            "Vous avez atteint le nombre limite d'articles permis dans votre panier. Contactez-nous, il nous fera plaisir d'augmenter votre limite."
          );
        }
      },
    }
  );

  function setValuesForCurrentWeek() {
    if (props.weekFirstDay) {
      var endOfWeekDate = new Date(props.weekFirstDay.date);
      endOfWeekDate.setDate(endOfWeekDate.getDate() + 6);
      setDateRange([props.weekFirstDay.date, endOfWeekDate]);
    }
  }

  useEffect(() => {
    setValuesForCurrentWeek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openDialog]);

  function closeDialog() {
    props.setOpenAddMealsToGroceryListDialog(false);
  }

  function handleDateChange(newValue) {
    setDateRange([newValue[0], newValue[1] ?? newValue[0]]);
  }

  return (
    <div>
      <Dialog open={props.openDialog} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{"Ajouter des repas à votre liste d'épicerie"}</Box>
            <Box>
              <IconButton
                onClick={() => {
                  closeDialog();
                }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" align="center">
            Sélectionner la plage de dates des repas
          </Typography>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                calendars={2}
                displayStaticWrapperAs="desktop"
                value={dateRange}
                onChange={(newValue) => handleDateChange(newValue)}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={addMealsToGroceryListMutation}
            disabled={isLoading || addingMealsLoading || numberOfMealsSelected < 1}
            color="primary"
            startIcon={isLoading || addingMealsLoading ? <CircularProgress size={20} /> : <AddShoppingCartIcon />}>
            {numberOfMealsSelected < 1 ? "Aucun repas dans cette plage" : `Ajouter ${numberOfMealsSelected} repas`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useContext, useState } from "react";
import { AuthContext } from "../../../common/contexts/Auth";
import {
  Button,
  DialogActions,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import "cropperjs/dist/cropper.css";
import { Cropper } from "react-cropper";
import { useQuery } from "react-query";
import * as recipesApi from "../recipesApi";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
import { toast } from "react-toastify";

const steps = ["Télécharger une image", "Sélectionnez les ingrédients", "Sélectionnez les étapes"];

export default function RecipeImportFromImageDialog(props) {
  const { currentUser } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState();
  const [ingredientsCropperData, setIngredientsCropperData] = useState(undefined);
  const [stepsCropperData, setStepsCropperData] = useState(undefined);
  const [ingredientsCropper, setIngredientsCropper] = useState();
  const [stepsCropper, setStepsCropper] = useState();

  const { isFetching, isLoading, refetch } = useQuery(
    "scrapedRecipe",
    async () => {
      const imagesObject = {
        ingredientsImage: ingredientsCropper.getCroppedCanvas().toDataURL(),
        instructionsImage: stepsCropper.getCroppedCanvas().toDataURL(),
      };
      const token = await currentUser.getIdToken();
      return await recipesApi.scrapeRecipeFromImage(imagesObject, token);
    },
    { enabled: false, retry: false }
  );

  function handleImageChange(event, section) {
    event.persist();
    if (event.target.files.length !== 0) {
      var fileName = event.target.files[0].name;
      if (!fileName.endsWith(".jpg") && !fileName.endsWith(".jpeg") && fileName.endsWith(".png")) {
        alert(event.target.files[0].name + " est invalide. Extensions permises sont: jpg, jpeg ou png.");
      } else if (event.target.files[0].size > 1000000) {
        alert(event.target.files[0].name + " est invalide. L'image ne peut pas avoir une taille supérieur à 1MB.");
      } else {
        const reader = new FileReader();
        let file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setImage(reader.result);
          setActiveStep(1);
        };
      }
    }
  }

  function resetForm() {
    setActiveStep(0);
    setIngredientsCropperData(undefined);
    setStepsCropperData(undefined);
  }

  function setCropperDatas() {
    if (ingredientsCropper) setIngredientsCropperData(ingredientsCropper.getData());
    if (stepsCropper) setStepsCropperData(stepsCropper.getData());
  }

  function handleNext() {
    setCropperDatas();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setCropperDatas();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleSubmit() {
    refetch().then((res) => {
      if (res.isSuccess) {
        props.handleImportRecipe(res.data);
        resetForm();
        props.close();
      } else {
        toast.error("Malheureusement, nous ne sommes pas en mesure d'extraire une recette de cette image.");
      }
    });
  }

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth="xl">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{"Importer une recette à partir d'une image"}</Box>
          <Box>
            <IconButton
              onClick={() => {
                resetForm();
                props.close();
              }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <br />
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center", minHeight: "60vh", maxHeight: "60vh" }}>
          {activeStep === 0 && (
            <>
              <br />
              <br />
              <Button
                variant="outlined"
                component="label"
                color="primary"
                className="buttonMargins"
                onChange={handleImageChange}
                startIcon={<ImageIcon />}>
                <input accept="image/*" type="file" hidden />
                {"Sélectionnez une image"}
              </Button>
            </>
          )}
          {activeStep === 1 && (
            <Cropper
              style={{ height: "60vh", width: "75%", display: "inline-block" }}
              initialAspectRatio={2}
              src={image}
              viewMode={2}
              background={true}
              responsive={false}
              wheelZoomRatio={0.25}
              data={ingredientsCropperData}
              onInitialized={(instance) => {
                setIngredientsCropper(instance);
              }}
            />
          )}
          {activeStep === 2 && (
            <Cropper
              style={{ height: "60vh", width: "75%", display: "inline-block" }}
              initialAspectRatio={2}
              src={image}
              viewMode={2}
              background={true}
              responsive={false}
              wheelZoomRatio={0.25}
              data={stepsCropperData}
              onInitialized={(instance) => {
                setStepsCropper(instance);
              }}
            />
          )}
          <br />
        </div>
      </DialogContent>
      <DialogActions>
        {activeStep !== 0 && (
          <Button variant="outlined" color="secondary" onClick={handleBack}>
            Précédent
          </Button>
        )}
        {activeStep === 1 && (
          <Button variant="outlined" onClick={handleNext}>
            Suivant
          </Button>
        )}
        {activeStep === 2 && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isFetching || isLoading}
            startIcon={isFetching || isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}>
            Importer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

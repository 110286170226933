import React, { useState } from "react";
import { Dialog, Button, DialogContent, DialogTitle, Tab, Tabs, Box, IconButton } from "@mui/material";
import RecipesSearch from "../../../modules/recipes/components/RecipesSearch";
import { Formik, Form } from "formik";
import mealValidationSchema from "../../../common/utils/mealValidationSchema";
import FormikTextField from "../../../common/components/FormikTextField";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CloseIcon from "@mui/icons-material/Close";

function AddMealDialog(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [diaglogFormSubmitted, setDialogFormSubmitted] = useState(false);

  function closeDialog() {
    props.setOpenAddMealDialog(false);
    setDialogFormSubmitted(false);
    setSelectedTab(0);
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={closeDialog}
        fullWidth
        maxWidth="lg"
        classes={{ paper: "absoluteDialog" }}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
                <Tab icon={<MenuBookIcon />} iconPosition="start" label="Livre de recettes" />
                <Tab icon={<RestaurantIcon />} iconPosition="start" label="Repas personnalisé" />
              </Tabs>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  closeDialog();
                }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent id="scrollableDiv">
          <div value={selectedTab} hidden={selectedTab !== 0}>
            <RecipesSearch handleRecipeClick={props.handleRecipeClick} />
          </div>
          <div value={selectedTab} hidden={selectedTab !== 1}>
            <Formik
              validateOnChange={diaglogFormSubmitted}
              validateOnBlur={false}
              initialValues={{
                description: "",
              }}
              validationSchema={mealValidationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                setSelectedTab(0);
                props.handleAddPersonalizedMeal(data);
                setSubmitting(false);
              }}>
              {({ values, errors, isSubmitting }) => (
                <Form>
                  <FormikTextField label="Description du repas" name={"description"} multiline />
                  <div style={{ textAlign: "right" }}>
                    <Button type="submit" disabled={isSubmitting} variant="outlined" color="primary">
                      Ajouter le repas
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddMealDialog;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../common/contexts/Auth";
import { Button, Container, Typography } from "@mui/material";
import ListIcon from "@mui/icons-material/List";

export default function HomePage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  return (
    <Container maxWidth="xl" disableGutters>
      <Typography variant="h2">Accueil</Typography>
      {currentUser ? (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => navigate("/recipes")}
          startIcon={<ListIcon />}>
          Mes recettes
        </Button>
      ) : (
        <Button variant="outlined" color="primary" size="large" onClick={() => navigate("/login")}>
          Commencer
        </Button>
      )}
    </Container>
  );
}

import React, { useContext } from "react";
import {
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../common/contexts/Auth";
import * as baseApi from "../common/services/baseApi";
import * as pantryApi from "../modules/pantry/pantryApi";
import Loading from "../common/components/Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import PantryIngredientAutocompleteForm from "../modules/pantry/PantryIngredientAutocompleteForm";
import { toast } from "react-toastify";

export default function PantryPage() {
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const { data: groceryProductsOptions, isLoading: productsLoading } = useQuery("groceryProducts", async () => {
    const token = await currentUser.getIdToken();
    return await baseApi.getGroceryProducts(token);
  });

  const { data: pantryItems, isLoading: pantryLoading } = useQuery(
    "pantry",
    async () => {
      const token = await currentUser.getIdToken();
      return await pantryApi.getPantry(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { mutate: addPantryItemMutation } = useMutation(
    async (newItem) => {
      const token = await currentUser.getIdToken();
      return await pantryApi.addItem(newItem, token);
    },
    {
      onMutate: async (newItem) => {
        await queryClient.cancelQueries("pantry");
        const previousPantryItems = queryClient.getQueryData("pantry");
        queryClient.setQueryData("pantry", (old) => [...old, newItem]);
        return { previousPantryItems };
      },
      onSettled: () => {
        queryClient.invalidateQueries("pantry");
      },
      useErrorBoundary: (error) => error.response?.status !== 402,
      onError: (error, newItem, context) => {
        if (error.response?.status === 402) {
          queryClient.setQueryData("pantry", context.previousPantryItems);
          toast.error(
            "Vous avez atteint le nombre limite d'articles permis dans votre garde-manger. Contactez-nous, il nous fera plaisir d'augmenter votre limite."
          );
        }
      },
    }
  );

  const { mutate: deletePantryItemMutation } = useMutation(
    async (itemId) => {
      const token = await currentUser.getIdToken();
      return await pantryApi.deleteItem(itemId, token);
    },
    {
      onMutate: async (itemId) => {
        await queryClient.cancelQueries("pantry");
        const previousPantryItems = queryClient.getQueryData("pantry");
        queryClient.setQueryData("pantry", (old) => old.filter((x) => x.id !== itemId));
        return { previousPantryItems };
      },
      onSettled: () => {
        queryClient.invalidateQueries("pantry");
      },
      useErrorBoundary: true,
    }
  );

  return (
    <Container>
      <Grid container direction="row" spacing={4}>
        <Grid container item md={6}>
          <Typography variant="h4">Garde-Manger</Typography>
          <Typography variant="body1">
            {
              "Les ingrédients dans votre Garde-Manger sont ignorés lorsque vous ajouter les ingrédients d'une recette ou d'un repas à votre liste d'épicerie."
            }
          </Typography>
        </Grid>
        <Grid container item md={6}>
          <div style={{ width: "100%" }}>
            <PantryIngredientAutocompleteForm
              groceryProductsOptions={groceryProductsOptions}
              addPantryItem={addPantryItemMutation}></PantryIngredientAutocompleteForm>
          </div>
        </Grid>
        <Grid container item md={6}>
          <div style={{ width: "100%" }}>
            <List>
              {(productsLoading || pantryLoading) && <Loading />}
              {pantryItems &&
                pantryItems.map((item) => {
                  return (
                    <ListItem key={item.id}>
                      <ListItemIcon>
                        <CircleIcon fontSize="10" htmlColor="#044075" />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => deletePantryItemMutation(item.id)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

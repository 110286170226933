import * as yup from "yup";

const stepValidationSchema = yup.object({
  description: yup
    .string()
    .required("La description de l'étape est obligatoire")
    .max(500, "La description de l'étape ne peut contenir plus de 500 caractères"),
});

export default stepValidationSchema;

import * as yup from "yup";

const mealValidationSchema = yup.object({
  description: yup
    .string()
    .required("La description du repas est obligatoire")
    .max(100, "La description du repas ne peut contenir plus de 100 caractères"),
});

export default mealValidationSchema;

import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function Loading() {
  return (
    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}>
      <CircularProgress />
    </Box>
  );
}

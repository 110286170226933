import { Button, Dialog, DialogContent, DialogTitle, Typography, Box, IconButton } from "@mui/material";
import React from "react";
import ImageIcon from "@mui/icons-material/Image";
import WebIcon from "@mui/icons-material/Web";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../Recipes.module.scss";

export default function RecipeImportOptionsDialog(props) {
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{"Importer une recette"}</Box>
          <Box>
            <IconButton onClick={props.close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Button
          className={styles.importRecipeOptionButton}
          variant="outlined"
          onClick={() => {
            props.openRecipeWebImportDialog();
            props.close();
          }}>
          <WebIcon fontSize="large" />
          <Typography>À partir d'une page web</Typography>
        </Button>
        <Button
          className={styles.importRecipeOptionButton}
          variant="outlined"
          onClick={() => {
            props.openRecipeImportFromImageDialog();
            props.close();
          }}>
          <ImageIcon fontSize="large" />
          <Typography>À partir d'une image (beta)</Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
}

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../common/contexts/Auth";
import {
  Typography,
  Grid,
  Container,
  Button,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  AvatarGroup,
  ListItemSecondaryAction,
  IconButton,
  ListItem,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemIcon,
  Checkbox,
  Menu,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import styles from "../modules/grocery-list/GroceryList.module.scss";
import RecipeIngredientDisplay from "../modules/recipes/components/RecipeIngredientDisplay";
import AddIcon from "@mui/icons-material/Add";
import GroceryListItemEditorDialog from "../modules/grocery-list/components/GroceryListItemEditorDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableRowsIcon from "@mui/icons-material/TableRows";
import KitchenIcon from "@mui/icons-material/Kitchen";
import { toast } from "react-toastify";
import GroceryListItemCategoryEditorDialog from "../modules/grocery-list/components/GroceryListItemCategoryEditorDialog";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as baseApi from "../common/services/baseApi";
import * as groceryListApi from "../modules/grocery-list/groceryListApi";
import * as pantryApi from "../modules/pantry/pantryApi";
import Loading from "../common/components/Loading";

export default function GroceryListPage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [openGroceryListItemEditorDialog, setOpenGroceryListItemEditorDialog] = useState(false);
  const [openGroceryListItemCategoryEditorDialog, setOpenGroceryListItemCategoryEditorDialog] = useState(false);
  const [editingGroceryListItem, setEditingGroceryListItem] = useState(undefined);
  const [itemActionMenuAnchor, setItemActionMenuAnchor] = useState(null);
  const [addItemToPantryDialog, setAddItemToPantryDialog] = useState(undefined);
  const openItemActionMenu = Boolean(itemActionMenuAnchor);

  const queryClient = useQueryClient();

  const { data: unitsOfMeasurementOptions, isLoading: unitsIsLoading } = useQuery(
    "unitsOfMeasurement",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getUnitsOfMeasurement(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { data: groceryProductsOptions } = useQuery("groceryProducts", async () => {
    const token = await currentUser.getIdToken();
    return await baseApi.getGroceryProducts(token);
  });

  const { data: groceryCategoriesOptions, isLoading: categoriesLoading } = useQuery(
    "groceryCategories",
    async () => {
      const token = await currentUser.getIdToken();
      return await baseApi.getGroceryCategories(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { data: groceryListItems, isLoading: groceryListLoading } = useQuery(
    "groceryList",
    async () => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.getGroceryList(token);
    },
    {
      useErrorBoundary: true,
    }
  );

  const { mutate: toggleGroceryListItemStrikeThroughMutation } = useMutation(
    async (itemId) => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.toggleItemStrikeThrough(itemId, token);
    },
    {
      onMutate: async (itemId) => {
        await queryClient.cancelQueries("groceryList");
        const previousGroceryListItems = queryClient.getQueryData("groceryList");
        queryClient.setQueryData("groceryList", (old) => {
          const itemIndex = old.findIndex((item) => item.id === itemId);
          const newStrikeThroughValue = old[itemIndex].strikethrough;
          old[itemIndex].strikethrough = !newStrikeThroughValue;
          return [...old];
        });
        return { previousGroceryListItems };
      },
      onSettled: () => {
        queryClient.invalidateQueries("groceryList");
      },
      useErrorBoundary: true,
    }
  );

  const { mutate: deleteGroceryListItemMutation } = useMutation(
    async (itemId) => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.deleteItem(itemId, token);
    },
    {
      onMutate: async (itemId) => {
        await queryClient.cancelQueries("groceryList");
        const previousGroceryListItems = queryClient.getQueryData("groceryList");
        queryClient.setQueryData("groceryList", (old) => old.filter((x) => x.id !== itemId));
        return { previousGroceryListItems };
      },
      onSettled: () => {
        queryClient.invalidateQueries("groceryList");
      },
      useErrorBoundary: true,
    }
  );

  const { mutate: deleteRecipeInGroceryListMutation } = useMutation(
    async (recipeId) => {
      const token = await currentUser.getIdToken();
      return await groceryListApi.deleteRecipe(recipeId, token);
    },
    {
      onMutate: async (recipeId) => {
        await queryClient.cancelQueries("groceryList");
        const previousGroceryListItems = queryClient.getQueryData("groceryList");
        queryClient.setQueryData("groceryList", (old) =>
          old.filter((x) => !(x.recipes.length === 1 && x.recipes.some((recipe) => recipe.id === recipeId)))
        );
        return { previousGroceryListItems };
      },
      onSettled: () => {
        queryClient.invalidateQueries("groceryList");
      },
      useErrorBoundary: true,
    }
  );

  const { mutate: addPantryItemMutation } = useMutation(
    async (newItem) => {
      const token = await currentUser.getIdToken();
      return await pantryApi.addItem(newItem, token);
    },
    {
      onSuccess: () => {
        toast.success("L'ingrédient a été ajouté à votre Garde-Manger");
      },
      useErrorBoundary: true,
    }
  );

  function generateRecipesList() {
    let result = [];
    if (groceryListItems) {
      groceryListItems.forEach((item) => {
        if (item.recipes) {
          item.recipes.forEach((recipe) => {
            result.push({
              id: recipe.id,
              recipeUuid: recipe.recipeUuid,
              name: recipe.name,
              color: stringToColour(recipe.name),
            });
          });
        }
      });
      result = result.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
    }
    return result;
  }

  function categorizeGroceryListItems() {
    let result = {};
    if (groceryListItems && groceryCategoriesOptions) {
      result = groceryListItems.reduce(function (r, a) {
        const categoryKey = groceryCategoriesOptions.find((x) => x.id === a.groceryCategoryId);
        r[categoryKey.name] = r[categoryKey.name] || [];
        r[categoryKey.name].push(a);
        return r;
      }, Object.create(null));
    }
    return result;
  }

  function stringToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  function handleOpenItemActionMenu(event, item) {
    setItemActionMenuAnchor(event.currentTarget);
  }

  function handleCloseItemActionMenu() {
    setItemActionMenuAnchor(null);
  }

  const recipes = generateRecipesList();
  const categorizedGroceryListItems = categorizeGroceryListItems();

  return (
    <Container>
      <Typography variant="h4" component="span">
        <p style={{ textAlign: "left" }}>
          Liste d'épicerie
          <span style={{ float: "right" }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              className="buttonMargins"
              onClick={() => {
                setEditingGroceryListItem(undefined);
                setOpenGroceryListItemEditorDialog(true);
              }}>
              Ajouter un article
            </Button>
          </span>
        </p>
      </Typography>
      {(groceryListLoading || unitsIsLoading || categoriesLoading) && <Loading />}
      <Grid container direction="row">
        <Grid item md={6}>
          {categorizedGroceryListItems &&
            Object.keys(categorizedGroceryListItems).map((groceryCategory) => {
              return (
                <Accordion key={groceryCategory} className={styles.groceryListAccordion} defaultExpanded disableGutters>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{groceryCategory}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List dense>
                      {categorizedGroceryListItems[groceryCategory].map((item) => {
                        return (
                          <ListItem
                            key={item.id}
                            disablePadding
                            button
                            onClick={() => toggleGroceryListItemStrikeThroughMutation(item.id)}>
                            <ListItemIcon>
                              <Checkbox checked={item.strikethrough} />
                            </ListItemIcon>
                            <ListItemAvatar>
                              <AvatarGroup max={4} spacing="small" classes={{ avatar: styles.recipeAvatar }}>
                                {item.recipes.map((recipe) => {
                                  let foundRecipe = recipes.find((r) => r.id === recipe.id);
                                  return (
                                    foundRecipe && (
                                      <Avatar key={foundRecipe.id} sx={{ bgcolor: foundRecipe.color }}>
                                        {" "}
                                      </Avatar>
                                    )
                                  );
                                })}
                              </AvatarGroup>
                            </ListItemAvatar>
                            <ListItemText className={styles.groceryListItemText}>
                              <Typography variant="body2">
                                <RecipeIngredientDisplay
                                  recipeIngredient={item}
                                  unitsOfMeasurement={unitsOfMeasurementOptions}
                                />
                              </Typography>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton
                                size="large"
                                onClick={(event) => {
                                  setEditingGroceryListItem(item);
                                  handleOpenItemActionMenu(event);
                                }}>
                                <MoreVertIcon color="action" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Grid>
        <Grid item md={6}>
          {recipes && recipes.length > 0 && (
            <div>
              <Typography variant="h6" className={styles.groceryCategory} align="right">
                Recettes
              </Typography>
              {recipes.map((recipe) => {
                return (
                  <div key={recipe.id} className={styles.alignRecipes}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => deleteRecipeInGroceryListMutation(recipe.id)}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    <Link
                      component="button"
                      underline="hover"
                      variant="body1"
                      onClick={() => {
                        navigate(`/recipe/${recipe.recipeUuid}`);
                      }}
                      color="common.black">
                      {recipe.name}
                    </Link>
                    <Avatar className={styles.recipeAvatar} sx={{ bgcolor: recipe.color }}>
                      {" "}
                    </Avatar>
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>

      <Menu anchorEl={itemActionMenuAnchor} open={openItemActionMenu} onClose={handleCloseItemActionMenu}>
        <MenuItem
          onClick={() => {
            setOpenGroceryListItemEditorDialog(true);
            handleCloseItemActionMenu();
          }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenGroceryListItemCategoryEditorDialog(true);
            handleCloseItemActionMenu();
          }}>
          <ListItemIcon>
            <TableRowsIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Changer de catégorie</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAddItemToPantryDialog(editingGroceryListItem);
            handleCloseItemActionMenu();
          }}>
          <ListItemIcon>
            <KitchenIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Ajouter à votre Garde-Manger</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            deleteGroceryListItemMutation(editingGroceryListItem.id);
            handleCloseItemActionMenu();
          }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>

      <GroceryListItemEditorDialog
        openDialog={openGroceryListItemEditorDialog}
        closeDialog={() => setOpenGroceryListItemEditorDialog(false)}
        unitsOfMeasurementOptions={unitsOfMeasurementOptions}
        groceryProductsOptions={groceryProductsOptions}
        editingGroceryListItem={editingGroceryListItem}
      />

      <GroceryListItemCategoryEditorDialog
        openDialog={openGroceryListItemCategoryEditorDialog}
        closeDialog={() => setOpenGroceryListItemCategoryEditorDialog(false)}
        groceryCategoriesOptions={groceryCategoriesOptions}
        editingGroceryListItem={editingGroceryListItem}
      />

      <Dialog open={Boolean(addItemToPantryDialog)} onClose={() => setAddItemToPantryDialog(false)}>
        <DialogTitle>
          {`Ajouter ${addItemToPantryDialog && addItemToPantryDialog.name} à votre Garde-Manger? `}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {`L'article sera retiré de votre liste d'épicerie et l'ingrédient sera ignoré la prochaine fois que vous ajouter les ingrédients d'une recettes à votre liste.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              let newItem = {
                name: addItemToPantryDialog.name,
                groceryProductId: addItemToPantryDialog.groceryProductId,
              };
              addPantryItemMutation(newItem);
              deleteGroceryListItemMutation(addItemToPantryDialog.id);
              setAddItemToPantryDialog(undefined);
            }}
            color="primary">
            Ajouter
          </Button>
          <Button onClick={() => setAddItemToPantryDialog(undefined)} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

import React from "react";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import pantryItemValidationSchema from "../../common/utils/pantryItemValidationSchema";
import AddIcon from "@mui/icons-material/AddCircle";
import RecipeIngredientNameAutocomplete from "../recipes/components/RecipeIngredientNameAutocomplete";

export default function PantryIngredientAutocompleteForm(props) {
  const itemFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: undefined,
    },
    validationSchema: pantryItemValidationSchema,
    onSubmit: (data) => {
      handleNameSubmit(data);
    },
  });

  function handleAutoCompleteOnChange(newValue) {
    if (newValue) {
      itemFormik.setFieldValue("name", newValue).then(() => {
        itemFormik.submitForm();
      });
    } else {
      itemFormik.setFieldValue("name", undefined);
    }
  }

  function createNewItem(itemName) {
    let newItem = {
      name: itemName,
      groceryProductId: undefined,
    };
    if (props.groceryProductsOptions && itemName) {
      var selectedGroceryProduct = props.groceryProductsOptions.find(
        (g) => g.name.toUpperCase() === itemName.toUpperCase()
      );
      if (selectedGroceryProduct) {
        newItem.name = selectedGroceryProduct.name;
        newItem.groceryProductId = selectedGroceryProduct.id;
      }
    }
    return newItem;
  }

  function handleNameSubmit(item) {
    const newItem = createNewItem(item.name);
    props.addPantryItem(newItem);
    itemFormik.resetForm();
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        itemFormik.submitForm();
      }}>
      <div style={{ display: "flex" }}>
        <RecipeIngredientNameAutocomplete
          groceryProductsOptions={props.groceryProductsOptions || []}
          groceryProductName={itemFormik.values.name}
          handleIngredientSelect={(newValue) => handleAutoCompleteOnChange(newValue)}
          setIngredientName={(newValue) => itemFormik.setFieldValue("name", newValue)}
          error={itemFormik.errors.name}
          startAdornment={
            <React.Fragment>
              <IconButton type="submit">
                <AddIcon color="primary" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </form>
  );
}

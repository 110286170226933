import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../common/contexts/Auth";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Autocomplete,
  IconButton,
  DialogContent,
} from "@mui/material";
import { useFormik } from "formik";
import recipeValidationSchema from "../../../common/utils/recipeValidationSchema";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import EditIcon from "@mui/icons-material/Edit";
import RecipeDisplay from "./RecipeDisplay";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import RecipeIngredientsEditorDialog from "./RecipeIngredientsEditorDialog";
import RecipeInstructionsEditorDialog from "./RecipeInstructionsEditorDialog";
import RecipeImportFromWebDialog from "./RecipeImportFromWebDialog";
import { useMutation } from "react-query";
import * as recipesApi from "../recipesApi";
import RecipeImportOptionsDialog from "./RecipeImportOptionsDialog";
import RecipeImportFromImageDialog from "./RecipeImportFromImageDialog";
import { Box } from "@mui/system";
import { toast } from "react-toastify";

function RecipeEditor(props) {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [recipe, setRecipe] = useState([]);

  const [recipeFormSubmitted, setRecipeFormSubmitted] = useState(false);

  const [openIngredientsDialog, setOpenIngredientsDialog] = useState(false);
  const [openStepsDialog, setOpenStepsDialog] = useState(false);
  const [openRecipeImportFromWebDialog, setOpenRecipeImportFromWebDialog] = useState(false);
  const [openRecipeImportOptionsDialog, setOpenRecipeImportOptionsDialog] = useState(false);
  const [openRecipeImportFromImageDialog, setOpenRecipeImportFromImageDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { mutate: addRecipeMutation } = useMutation(
    async (newRecipe) => {
      const token = await currentUser.getIdToken();
      return await recipesApi.addRecipe(newRecipe, token);
    },
    {
      onSuccess: (res) => {
        navigate(`/recipe/${res}`);
      },
      useErrorBoundary: (error) => error.response?.status !== 402,
      onError: (error) => {
        if (error.response?.status === 402) {
          toast.error(
            "Vous avez atteint le nombre limite de recettes permis. Contactez-nous, il nous fera plaisir d'augmenter votre limite."
          );
        }
      },
    }
  );

  const { mutate: updateRecipeMutation } = useMutation(
    async (updatedRecipe) => {
      const token = await currentUser.getIdToken();
      return await recipesApi.udpdateRecipe(updatedRecipe, token);
    },
    {
      onSuccess: (res) => {
        navigate(`/recipe/${res}`);
      },
      useErrorBoundary: true,
    }
  );

  const { mutate: deleteRecipeMutation } = useMutation(
    async (id) => {
      const token = await currentUser.getIdToken();
      return await recipesApi.deleteRecipe(id, token);
    },
    {
      onSuccess: () => {
        navigate(`/recipes`);
      },
      useErrorBoundary: true,
    }
  );

  function closeDialog() {
    setOpenIngredientsDialog(false);
    setOpenStepsDialog(false);
    setOpenDeleteDialog(false);
    setOpenCancelDialog(false);
  }

  //Update Page when editing recipe and we get recipe from server
  useEffect(() => {
    if (props.recipe) {
      setRecipe(props.recipe);
      recipeFormik.setFieldValue("name", props.recipe.name);
      recipeFormik.setFieldValue("prepTime", props.recipe.prepTime);
      recipeFormik.setFieldValue("cookTime", props.recipe.cookTime);
      recipeFormik.setFieldValue("yield", props.recipe.yield);
      if (props.recipe.tags) {
        recipeFormik.setFieldValue(
          "tags",
          props.recipe.tags.map((e) => {
            return { id: e.id, name: e.name };
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [props.recipe]);

  //Handle form submit
  function handleRecipeSubmit(data, { setSubmitting }) {
    setSubmitting(true);
    var recipeObject = {
      Id: recipe.id,
      RecipeUuid: recipe.recipeUuid,
      Name: recipe.name,
      PrepTime: recipe.prepTime,
      CookTime: recipe.cookTime,
      Yield: recipe.yield,
      Tags: data.tags.map((e) => {
        return { Id: e.id };
      }),
      RecipeInstructions: recipe.recipeInstructions
        ? recipe.recipeInstructions.map((e, index) => {
            return { Number: index + 1, Description: e.description };
          })
        : [],
      RecipeIngredients: recipe.recipeIngredients
        ? recipe.recipeIngredients.map((e) => {
            return {
              Amount: e.amount,
              UnitOfMeasurementId: e.unitOfMeasurementId,
              Name: e.name,
              Details: e.details,
              GroceryProductId: e.groceryProductId,
            };
          })
        : [],
      ImageId: recipe.imageId,
      RecipeSource: recipe.recipeSource,
      Image: recipe.image && {
        Id: recipe.imageId || 0,
        ImageData: recipe.image.imageData ? recipe.image.imageData : null,
        ImagePublicId: recipe.image.imagePublicId,
        ImageSource: recipe.image.imageSource,
      },
    };

    if (recipe.id) {
      updateRecipeMutation(recipeObject);
    } else {
      addRecipeMutation(recipeObject);
    }
  }

  function handleImportRecipe(data) {
    setRecipe(data);
    recipeFormik.setFieldValue("name", data.name || "");
    recipeFormik.setFieldValue("prepTime", data.prepTime || "");
    recipeFormik.setFieldValue("cookTime", data.cookTime || "");
    recipeFormik.setFieldValue("yield", data.yield || "");
    recipeFormik.setFieldValue("tags", []);
    closeDialog();
  }

  function handleDeleteRecipe() {
    deleteRecipeMutation(recipe.id);
  }

  function handleTagsChange(tags) {
    recipeFormik.setFieldValue("tags", tags);
    setRecipe((recipe) => {
      recipe.tags = tags.map((e) => {
        return { name: e.name };
      });
      return recipe;
    });
  }

  function saveIngredients(ingredients) {
    setRecipe((recipe) => {
      recipe.recipeIngredients = ingredients.map((e) => {
        return {
          amount: e.amount,
          unitOfMeasurementId: e.unitOfMeasurementId,
          name: e.name,
          details: e.details,
          groceryProductId: e.groceryProductId,
        };
      });
      return recipe;
    });
    closeDialog();
  }

  function saveInstructions(instructions) {
    setRecipe((recipe) => {
      recipe.recipeInstructions = instructions.map((e, index) => {
        return { number: index + 1, description: e.description };
      });
      return recipe;
    });
    closeDialog();
  }

  function setRecipeDisplayImage(imageData) {
    setRecipe((recipe) => {
      recipe.image = { imageData: imageData };
      return { ...recipe };
    });
  }

  //Image
  function handleImageChange(event) {
    event.persist();
    if (event.target.files.length !== 0) {
      var fileName = event.target.files[0].name;
      if (!fileName.endsWith(".jpg") && !fileName.endsWith(".jpeg") && !fileName.endsWith(".png")) {
        alert(event.target.files[0].name + " est invalide. Extensions permises sont: jpg, jpeg ou png.");
      } else if (event.target.files[0].size > 1000000) {
        alert(event.target.files[0].name + " est invalide. L'image ne peut pas avoir une taille supérieur à 1MB.");
      } else {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setRecipeDisplayImage(reader.result);
        };
      }
    }
  }

  const recipeFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: recipeFormSubmitted,
    validateOnBlur: false,
    initialValues: {
      name: "",
      prepTime: "",
      cookTime: "",
      yield: "",
      tags: [],
    },
    validationSchema: recipeValidationSchema,
    onSubmit: (data, { setSubmitting }) => {
      handleRecipeSubmit(data, { setSubmitting });
    },
  });

  return (
    <div className="RecipeEditor">
      <Container maxWidth="xl" disableGutters>
        <div style={{ display: "flex" }}>
          <div style={{ width: "25%", borderRight: "solid #044075", marginLeft: "5px" }}>
            <Typography variant="h4" align="left">
              {recipe.id ? "Modifier une recette" : "Ajouter une recette"}
            </Typography>
            <form onSubmit={recipeFormik.handleSubmit}>
              <Grid spacing={5} container direction="row">
                <Grid item xs={11}>
                  {recipe.id ? null : (
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenRecipeImportOptionsDialog(true)}
                      startIcon={<PublishIcon />}>
                      Importer une recette
                    </Button>
                  )}
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    label="Nom de la recette"
                    name="name"
                    helperText={recipeFormik.errors.name}
                    error={!!recipeFormik.errors.name}
                    value={recipeFormik.values.name || ""}
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      recipeFormik.handleChange(e);
                      setRecipe((recipe) => {
                        recipe.name = e.target.value;
                        return recipe;
                      });
                    }}
                  />
                  <TextField
                    type="number"
                    label="Temps de préparation"
                    name="prepTime"
                    helperText={recipeFormik.errors.prepTime}
                    error={!!recipeFormik.errors.prepTime}
                    value={recipeFormik.values.prepTime}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
                    }}
                    onChange={(e) => {
                      recipeFormik.handleChange(e);
                      setRecipe((recipe) => {
                        recipe.prepTime = e.target.value;
                        return recipe;
                      });
                    }}
                  />
                  <TextField
                    type="number"
                    label="Temps de cuisson"
                    name="cookTime"
                    helperText={recipeFormik.errors.cookTime}
                    error={!!recipeFormik.errors.cookTime}
                    value={recipeFormik.values.cookTime}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
                    }}
                    onChange={(e) => {
                      recipeFormik.handleChange(e);
                      setRecipe((recipe) => {
                        recipe.cookTime = e.target.value;
                        return recipe;
                      });
                    }}
                  />
                  <TextField
                    type="number"
                    label="Portions"
                    name="yield"
                    helperText={recipeFormik.errors.yield}
                    error={!!recipeFormik.errors.yield}
                    value={recipeFormik.values.yield}
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      recipeFormik.handleChange(e);
                      setRecipe((recipe) => {
                        recipe.yield = e.target.value;
                        return recipe;
                      });
                    }}
                  />
                  <Autocomplete
                    name="tags"
                    value={recipeFormik.values.tags}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    multiple
                    options={props.recipeTagsChoices || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      recipeFormik.handleChange(event);
                      handleTagsChange(value);
                    }}
                    filterSelectedOptions={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={recipeFormik.errors.tags}
                        error={!!recipeFormik.errors.tags}
                        label="Catégories"
                        fullWidth
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    className="buttonMargins"
                    fullWidth
                    onChange={handleImageChange}
                    startIcon={<PhotoCameraIcon />}>
                    <input accept="image/*" id="contained-button-file" type="file" hidden />{" "}
                    {recipe.image ? "Modifier l'image" : "Ajouter une image"}
                  </Button>
                  {recipe.recipeIngredients && recipe.recipeIngredients.length ? (
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      className="buttonMargins"
                      fullWidth
                      onClick={() => setOpenIngredientsDialog(true)}
                      startIcon={<EditIcon />}>
                      Modifier les ingrédients
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      className="buttonMargins"
                      fullWidth
                      onClick={() => setOpenIngredientsDialog(true)}
                      startIcon={<AddIcon />}>
                      Ajouter des ingrédients
                    </Button>
                  )}
                  {recipe.recipeInstructions && recipe.recipeInstructions.length ? (
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      className="buttonMargins"
                      fullWidth
                      onClick={() => setOpenStepsDialog(true)}
                      startIcon={<EditIcon />}>
                      Modifier les étapes
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      className="buttonMargins"
                      fullWidth
                      onClick={() => setOpenStepsDialog(true)}
                      startIcon={<AddIcon />}>
                      Ajouter des étapes
                    </Button>
                  )}
                </Grid>
                <Grid item xs={11}>
                  <Button
                    type="submit"
                    onClick={() => {
                      setRecipeFormSubmitted(true);
                    }}
                    variant="outlined"
                    color="primary"
                    className="buttonMargins"
                    fullWidth
                    disabled={recipeFormik.isSubmitting}
                    startIcon={recipeFormik.isSubmitting ? <CircularProgress size={20} /> : <SaveIcon />}>
                    Sauvegarder la recette
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      setOpenCancelDialog(true);
                    }}
                    variant="outlined"
                    color="secondary"
                    className="buttonMargins"
                    fullWidth
                    startIcon={<CancelIcon />}>
                    Annuler
                  </Button>
                  {recipe.id ? (
                    <Button
                      type="button"
                      onClick={() => setOpenDeleteDialog(true)}
                      variant="outlined"
                      color="secondary"
                      className="buttonMargins"
                      fullWidth
                      startIcon={<DeleteIcon />}>
                      Supprimer la recette
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </div>
          <div style={{ width: "75%" }}>
            <Typography variant="h5" align="center">
              Aperçu de la recette
            </Typography>
            <RecipeDisplay
              recipe={recipe}
              editingRecipe={true}
              unitsOfMeasurementOptions={props.unitsOfMeasurementOptions}
            />
          </div>
        </div>
      </Container>

      <RecipeIngredientsEditorDialog
        open={openIngredientsDialog}
        closeDialog={closeDialog}
        ingredients={recipe.recipeIngredients}
        saveIngredients={saveIngredients}
        unitsOfMeasurementOptions={props.unitsOfMeasurementOptions}
      />

      <RecipeInstructionsEditorDialog
        open={openStepsDialog}
        closeDialog={closeDialog}
        instructions={recipe.recipeInstructions}
        saveInstructions={saveInstructions}
      />

      <RecipeImportOptionsDialog
        open={openRecipeImportOptionsDialog}
        close={() => setOpenRecipeImportOptionsDialog(false)}
        openRecipeWebImportDialog={() => setOpenRecipeImportFromWebDialog(true)}
        openRecipeImportFromImageDialog={() => setOpenRecipeImportFromImageDialog(true)}></RecipeImportOptionsDialog>

      <RecipeImportFromImageDialog
        open={openRecipeImportFromImageDialog}
        close={() => setOpenRecipeImportFromImageDialog(false)}
        handleImportRecipe={handleImportRecipe}
        setRecipeDisplayImage={setRecipeDisplayImage}></RecipeImportFromImageDialog>

      <RecipeImportFromWebDialog
        open={openRecipeImportFromWebDialog}
        close={() => setOpenRecipeImportFromWebDialog(false)}
        handleImportRecipe={handleImportRecipe}
      />

      <Dialog open={openDeleteDialog} onClose={closeDialog}>
        <DialogTitle>{"Êtes-vous sûre de vouloir supprimer la recette?"}</DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteRecipe} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCancelDialog} onClose={closeDialog}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{"Quitter la modification?"}</Box>
            <Box>
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {"Êtes-vous certain de vouloir annuler sans sauvegarder? Vos changements seront perdus."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeDialog();
              navigate(-1);
            }}
            color="primary">
            Quitter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RecipeEditor;

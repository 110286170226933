import React from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

export default function RecipeIngredientUnitAutocomplete(props) {
  const selectedValue = props.unitsOfMeasurementOptions?.find((x) => x.id === props.selectedUnitOfMeasurementId);

  function handleAutoCompleteOnChange(event, value) {
    if (value !== null) {
      props.setIngredientUnitOfMeasurementId(value.id);
    } else {
      props.setIngredientUnitOfMeasurementId(null);
    }
  }

  return (
    <div key={props.selectedUnitOfMeasurementId}>
      <Autocomplete
        options={props.unitsOfMeasurementOptions || []}
        getOptionLabel={(option) => option.name}
        defaultValue={selectedValue}
        onChange={handleAutoCompleteOnChange}
        renderInput={(params) => <TextField {...params} label="Unités" variant="standard" />}
      />
    </div>
  );
}

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../common/contexts/Auth";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Chip,
  InputAdornment,
  TextField,
  Container,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Image, Transformation } from "cloudinary-react";
import styles from "../Recipes.module.scss";
import { useQuery, useInfiniteQuery } from "react-query";
import * as recipesApi from "../recipesApi";
import * as baseApi from "../../../common/services/baseApi";
import Loading from "../../../common/components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const RECIPES_PER_PAGE = 12;

export default function RecipesSearch(props) {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [selectedTags, setSelectedTags] = useState([]);
  const [recipeName, setRecipeName] = useState("");

  const { data: recipeTagsChoices } = useQuery("tags", async () => {
    const token = await currentUser.getIdToken();
    return await baseApi.getTags(token);
  });

  const {
    data: userRecipes,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["recipes", recipeName, selectedTags],
    async ({ pageParam = 1 }) => {
      const token = await currentUser.getIdToken();
      return await recipesApi.searchRecipes(token, recipeName, selectedTags, RECIPES_PER_PAGE, pageParam);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = Math.ceil(lastPage.totalCount / RECIPES_PER_PAGE);
        const nextPage = allPages.length + 1;
        return nextPage <= maxPages ? nextPage : undefined;
      },
      useErrorBoundary: true,
    }
  );

  function displayImage(recipe) {
    if (recipe.image && recipe.image.imageSource) {
      return <img width="300" src={recipe.image.imageSource} alt={recipe.name} />;
    } else if (recipe.image && recipe.image.imagePublicId) {
      return (
        <Image cloudName="mealzer" width="300" publicId={recipe.image.imagePublicId} dpr="auto">
          <Transformation crop="scale" fetchFormat="auto" />
        </Image>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <Grid container spacing={3} alignItems="center" justifyContent="center" style={{ textAlign: "center" }}>
        <Grid item xs={12} md={6} lg={3}>
          <Typography variant="h4">Livre de recettes</Typography>
        </Grid>
        <Grid item xs={11} md={6} lg={3}>
          <TextField
            label="Nom de la recette"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setRecipeName(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={11} md={6} lg={3}>
          <Autocomplete
            id="tags"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple
            options={recipeTagsChoices || []}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setSelectedTags(
                value.map((e) => {
                  return { Id: e.id, Name: e.name };
                })
              );
            }}
            renderInput={(params) => <TextField {...params} label="Catégories" fullWidth variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(`/addrecipe`);
            }}
            startIcon={<AddIcon />}>
            Ajouter une recette
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      {userRecipes && userRecipes.pages[0].totalCount === 0 && (
        <Typography variant="h5" align="center">
          Aucunes recettes trouvées
        </Typography>
      )}
      <Container style={{ overflow: "hidden" }}>
        {isLoading && <Loading />}
        {isSuccess && userRecipes && (
          <InfiniteScroll
            dataLength={userRecipes.pages.length}
            next={() => {
              if (hasNextPage) fetchNextPage();
            }}
            hasMore={hasNextPage}
            loader={<Loading />}
            scrollableTarget="scrollableDiv">
            <Grid container spacing={3} alignItems="stretch">
              {userRecipes.pages.map((page) => {
                return page.recipes.map((recipe) => (
                  <Grid key={recipe.id} item xs={6} md={4} lg={3}>
                    <Card className={styles.recipeCard}>
                      <CardActionArea
                        onClick={() => {
                          props.handleRecipeClick(recipe);
                        }}
                        className={styles.recipeCardActionArea}>
                        <CardMedia
                          className={styles.recipeCardMedia}
                          component={() => displayImage(recipe)}></CardMedia>
                        <CardContent>
                          <Typography variant="h5">{recipe.name}</Typography>
                          <Typography variant="body1" color="textSecondary" component="p">
                            Préparation : {recipe.prepTime} minutes
                          </Typography>
                          <Typography variant="body1" color="textSecondary" component="p">
                            Cuisson : {recipe.cookTime} minutes
                          </Typography>
                          <Typography variant="body1" color="textSecondary" component="p">
                            Portions : {recipe.yield}
                          </Typography>
                          {recipe.tags &&
                            recipe.tags.map((recipeTag) => (
                              <Chip key={recipeTag.name} size="small" label={recipeTag.name} />
                            ))}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ));
              })}
            </Grid>
          </InfiniteScroll>
        )}
      </Container>
      <br />
    </div>
  );
}

import { Grid } from "@mui/material";
import React from "react";

function formatIngredientAmount(number) {
  var roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100;
  var int_part = Math.trunc(roundedNumber);
  var float_part = Number(roundedNumber - int_part);
  if (float_part === 0.25) float_part = "¼";
  else if (float_part === 0.33) float_part = "⅓";
  else if (float_part === 0.5) float_part = "½";
  else if (float_part === 0.67 || float_part === 0.66) float_part = "⅔";
  else if (float_part === 0.75) float_part = "¾";

  if (int_part === 0 && typeof float_part === "string") return float_part;
  if (int_part > 0 && typeof float_part === "string") return `${int_part} ${float_part}`;
  return roundedNumber;
}

function RecipeIngredientDisplay(props) {
  const unitsOfMeasurement = props.unitsOfMeasurement;
  const recipeIngredient = props.recipeIngredient;

  var ingredientAmount =
    recipeIngredient.amount && recipeIngredient.amount > 0 ? formatIngredientAmount(recipeIngredient.amount) : "";

  var ingredientUnit = "";
  if (recipeIngredient.unitOfMeasurementId && unitsOfMeasurement) {
    var unitOfMeasurement = unitsOfMeasurement.find((unit) => unit.id === recipeIngredient.unitOfMeasurementId);
    if (unitOfMeasurement) {
      ingredientUnit =
        recipeIngredient.amount > 1 || recipeIngredient.amount < 1
          ? unitOfMeasurement.abbreviationPlural
          : unitOfMeasurement.abbreviationSingular;
    }
  }

  var ingredientDetails = "";
  if (recipeIngredient.details && recipeIngredient.details !== "") {
    if (recipeIngredient.name && recipeIngredient.name !== "") {
      ingredientDetails = `, ${recipeIngredient.details}`;
    } else {
      ingredientDetails = `${recipeIngredient.details}`;
    }
  }
  return (
    <Grid container direction="row" alignItems="center">
      <Grid lg={1.5}>{ingredientAmount}</Grid>
      <Grid lg={10.5}>
        {ingredientUnit}{" "}
        {recipeIngredient.groceryProductId ? <b>{recipeIngredient.name}</b> : <>{recipeIngredient.name}</>}
        {ingredientDetails}
      </Grid>
    </Grid>
  );
}

export default RecipeIngredientDisplay;
